const state = {
  albums: {},
  pickAlbums: {},
  loading: false,
};

const mutations = {
  setAlbums(state, albums) {
    state.albums = albums;
  },

  setPickAlbums(state, albums) {
    state.pickAlbums = albums;
  },
};

const actions = {
  setAlbums({ commit }, payload) {
    commit("setAlbums", payload);
  },

  setPickAlbums({ commit }, payload) {
    commit("setPickAlbums", payload);
  },
};

const getters = {
  albums: (state) => state.albums,
  pickAlbums: (state) => state.pickAlbums,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
