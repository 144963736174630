const apiUrl = process.env.BASE_URL;

const state = {
  user: null,
  token: null,
  login: false,
  loginStay: false,
};

const getters = {
  authToken: (state) => state.token,
  currentUser: (state) => state.user,
  isAuthenticated: (state) => !!state.token,
  getLogin: (state) => state.login,
  getLoginStay: (state) => state.loginStay,
};

const actions = {
  setLogin({ commit }, login) {
    commit("setLogin", login);
  },
  setUserData({ commit }, payload) {
    commit("SAVE_AUTH_DATA", payload);
  },
  setUserStatus({ commit }) {
    commit("setUserStatus");
  },
  setUserAddress({ commit }, payload) {
    const b64EncodeUnicode = (str) => {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    };

    const userAddressReverse = payload
      .split("")
      .reverse()
      .join("");

    const formattedUserAddress = b64EncodeUnicode(
      b64EncodeUnicode(userAddressReverse)
    );

    commit("setUserAddress", formattedUserAddress);
  },
  async logOut({ state, commit }) {
    await fetch(`${apiUrl}/api/auth/session`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });

    commit("REMOVE_AUTH_DATA");
  },

  setLoginStay({ commit }, payload) {
    commit("setLoginStay", payload);
  },
};

const mutations = {
  setLogin(state, login) {
    state.login = login;
  },
  setLoginStay(state, loginStay) {
    state.loginStay = loginStay;
  },
  SAVE_AUTH_DATA(state, payload) {
    if (payload.user["user_address"]) {
      const b64EncodeUnicode = (str) => {
        return btoa(
          encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
              return String.fromCharCode("0x" + p1);
            }
          )
        );
      };

      const userAddressReverse = payload.user["user_address"]
        .split("")
        .reverse()
        .join("");

      payload.user.user_address = b64EncodeUnicode(
        b64EncodeUnicode(userAddressReverse)
      );

      delete payload.user["email"];
    }

    state.user = payload.user;
    state.token = payload.token;

    localStorage.setItem("token", payload.token);
  },
  REMOVE_AUTH_DATA(state) {
    state.user = null;
    state.token = null;

    localStorage.removeItem("token");
  },
  setUserStatus(state) {
    state.user.status = "active";
  },
  setUserAddress(state, payload) {
    state.user.user_address = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
