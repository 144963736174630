<template>
  <li :class="{ red }" class="dots-item" @click="onItemClick">{{ title }}</li>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    red: {
      type: Boolean,
      defaults: false,
    },
  },

  methods: {
    onItemClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.dots-item {
  padding: 10px 5px;
  color: #6d7d89;
  line-height: 18px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(44, 145, 179, 0.3);
  cursor: pointer;
  transition: color 100ms ease, background 100ms ease;
}

.dots-item:not(.red):hover {
  color: #fff;
  background: #94aabb;
}

.dots-item.red {
  color: #e00c02;
}

.dots-item.red:hover {
  color: #fff;
  background: #e00c02;
}

.dots-item:not(:last-child) {
  margin-bottom: 6px;
}
</style>
