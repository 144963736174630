const state = {
  toast: {
    active: false,
    type: null,
    text: "",
  },
};

const mutations = {
  setToast(state, payload) {
    state.toast = payload;
  },
};

const actions = {
  setToast({ commit }, payload) {
    commit("setToast", payload);
  },
};

const getters = {
  toast: (state) => state.toast,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
