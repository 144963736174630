import axios from "axios";

const apiUrl = process.env.BASE_URL;

const state = {
  paymentData: {
    currency: "",
    system_id: "",
    connected: null,
  },
};

const mutations = {
  setPaymentData(state, payload) {
    state.paymentData = payload;
  },

  setPaymentStatus(state, payload) {
    state.paymentData.connected = payload;
  },
};

const actions = {
  async getPaymentData({ commit }) {
    let payload;

    try {
      const token = localStorage.getItem("token");

      const { data } = await axios.get(`${apiUrl}/api/payment_system`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.status === "connected")
        payload = {
          currency: data.currency,
          system_id: data.system_id,
          connected: true,
          sepa_data: data.sepa_data,
        };
      else
        payload = {
          connected: false,
        };
    } catch (error) {
      payload = {
        connected: false,
      };
    } finally {
      commit("setPaymentData", payload);
    }
  },

  setPaymentStatus({ commit }, payload) {
    commit("setPaymentStatus", payload);
  },
};

const getters = {
  paymentData: (state) => state.paymentData,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
