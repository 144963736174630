import createTagsData from './helpers';

export default function addMetaTags(d) {
  var head = document.querySelector('head');

  const metaTagUrl = document.querySelector('[property="og:url"]');
  const siteUrl = document.querySelector('[content="website"]');

  // metaTagUrl.remove();
  // siteUrl.remove();

  let title = head.querySelector('title');
  if (d.title) {
    title.innerHTML = d.title;
  }

  const tags = createTagsData(d);

  function createTags(tagsArr) {
    let fragment = document.createDocumentFragment();
    const tags = tagsArr.map((el, i, arr) => {
      const tag = document.createElement(el.tagName);
      tag.setAttribute(el.tagAttr, el.tagAttrValue);
      if (el.content) {
        tag.setAttribute('content', el.content);
      }
      fragment.appendChild(tag);
    });
    head.appendChild(fragment);
  }
  createTags(tags);
}

