import "../styles/index.styl";
import Vue from "vue";
import router from "./router/index.js";
import store from "./store/index.js";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBnWO8SQxVaFRwbieAzL4JIZyhTN8MI4Bo",
  authDomain: "pixchange-13cb6.firebaseapp.com",
  databaseURL: "https://pixchange-13cb6.firebaseio.com",
  projectId: "pixchange-13cb6",
  storageBucket: "pixchange-13cb6.appspot.com",
  messagingSenderId: "866939924435",
  appId: "1:866939924435:web:69289df759967cb35cc926",
  measurementId: "G-14ZE0DED5Z",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import AOS from "aos";
AOS.init();

import App from "./App.vue";
import helpers from "./services/helpers";
Vue.prototype.$helpers = helpers;

import RouterStorage from "vue-router-storage";
Vue.use(RouterStorage, {
  showLog: false,
  stayHere: true,
  instanceName: "$history",
});

import VueNumber from "vue-number-animation";
Vue.use(VueNumber);

import vueDebounce from "vue-debounce";
Vue.use(vueDebounce);

import VueLazyLoadVideo from "vue-lazyload-video";
Vue.use(VueLazyLoadVideo);

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

import TextareaAutosize from "vue-textarea-autosize";
Vue.use(TextareaAutosize);

import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll, {
  offset: -100,
  updateHistory: false,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount(".out");
