import { mobileDetect, isIosSafari } from "../../functions/mobileDetect";
import customProtocolCheck from "../../functions/customProtocolCheck";

const dapp = {
  data() {
    return {
      isMobile: mobileDetect(),
      downloadText: false,
    };
  },

  computed: {
    fallbackLink() {
      return isIosSafari()
        ? "https://apps.apple.com/app/metamask-blockchain-wallet/id1438144202"
        : "https://play.google.com/store/apps/details?id=io.metamask";
    },
  },

  methods: {
    openMetamask(routeName, routeQuery) {
      const apiUrl = process.env.BASE_URL;
      const apiUrlNew = apiUrl.replace("https://", "");
      const url = routeName === "Sale" ? "sell_offer" : routeName === "Transaction" ? "transaction" : routeName === "Mint" ? "mint_sell" : "user_verification";

      const params = new URLSearchParams(routeQuery).toString();

      const deeplinkUrl = `dapp://${apiUrlNew}/${url}?${params}`;

      if (isIosSafari()) {
        if (!this.downloadText) {
          this.downloadText = true;

          window.location.href = deeplinkUrl;

          return;
        }

        window.location.href = this.fallbackLink;

        return;
      }

      customProtocolCheck(
        `dapp://${apiUrlNew}/${url}?${params}`,
        () => {
          window.location.href = this.fallbackLink;
        },
        () => {
          window.location.href = deeplinkUrl;
        },
        250
      );
    },
  },
};

export default dapp;
