import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import auth from "./modules/auth";
import spotlight from "./modules/spotlight";
import albums from "./modules/albums";
import nftCollections from "./modules/nftCollections";
import nftData from "./modules/nftData";
import toast from "./modules/toast";
import paymentSystem from "./modules/paymentSystem";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: localStorage,
  modules: ["auth"],
});

const store = new Vuex.Store({
  modules: {
    auth,
    spotlight,
    albums,
    nftCollections,
    nftData,
    toast,
    paymentSystem,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
