import axios from "axios";

const apiUrl = process.env.BASE_URL;

const state = {
  nftData: [],
  nftTokens: [],
  collection: {},
  currency: {
    pixtokens: null,
    pixcoins: null,
  },
};

const mutations = {
  setNftData(state, payload) {
    state.nftData = payload;
  },

  setNftTokens(state, payload) {
    state.nftTokens = payload;
  },

  setCollection(state, payload) {
    state.collection = payload;
  },

  setCurrency(state, payload) {
    state.currency = payload;
  },
};

const actions = {
  async getCurrency({ commit }) {
    const token = localStorage.getItem("token");

    try {
      const { data } = await axios.get(`${apiUrl}/api/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      commit("setCurrency", {
        pixtokens: data.pixtokens,
        pixcoins: data.coins,
      });
    } catch (error) {
      console.log(error);
    }
  },

  setNftData({ commit }, payload) {
    commit("setNftData", payload);
  },

  async getNftTokens({ commit }, payload) {
    const token = localStorage.getItem("token");

    const fetchTokens = async (page = 1) => {
      let response;

      try {
        if (token) {
          response = await axios.get(
            `${apiUrl}/api/collections/${payload}/tokens?page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          response = await axios.get(
            `${apiUrl}/api/collections/${payload}/tokens?page=${page}`
          );
        }

        return response.data;
      } catch (error) {
        console.log(error);
      }
    };

    const response = await fetchTokens();

    if (response.total_pages > 1) {
      for (let page = 2; page <= response.total_pages; page++) {
        const pageResponse = await fetchTokens(page);
        response.collection.push(...pageResponse.collection);
      }
    }

    commit("setNftTokens", response.collection);
  },

  setNftTokens({ commit }, payload) {
    commit("setNftTokens", payload);
  },

  setCollection({ commit }, payload) {
    commit("setCollection", payload);
  },
};

const getters = {
  nftData: (state) => state.nftData,
  nftTokens: (state) => state.nftTokens,
  collection: (state) => state.collection,
  currency: (state) => state.currency,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
