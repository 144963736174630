<template>
  <div class="login-fade">
    <div class="login">
      <button @click="loginClose" class="login-close">
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.00342 2L20.6685 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M20.665 2L1.99993 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div class="login-form">
        <p class="login-title">Change Password</p>
        <p class="login-subtitle">
          We’ll email you a link to reset your password.
        </p>
        <form id="login" v-on:submit="submitForm($event)">
          <div class="input-wrapper">
            <svg
              class="input-icon"
              width="15"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.4615 12H14.5392C15.3452 12 15.9998 11.3628 16 10.581V1.41793C16 0.635655 15.3452 0 14.5394 0H1.4615C0.654828 0 0 0.635655 0 1.41793V10.581C0 11.3628 0.654828 12 1.4615 12ZM1.4615 1.42811L14.5277 1.41811H14.5278V10.5717L1.47145 10.5812L1.4615 1.42811Z"
                fill="#6F96D7"
              />
              <path
                d="M7.99907 8C7.83687 8 7.67467 7.94124 7.54241 7.82567L1.25092 2.30059C0.952869 2.03722 0.9142 1.57316 1.16794 1.26278C1.41997 0.950436 1.86602 0.910904 2.16493 1.17445L7.99924 6.29993L13.8344 1.17445C14.1343 0.911082 14.5795 0.950436 14.8324 1.26278C15.0853 1.57316 15.0475 2.03722 14.7493 2.30059L8.45556 7.82567C8.32347 7.94124 8.16127 8 7.99907 8Z"
                fill="#6F96D7"
              />
            </svg>
            <input
              type="text"
              class="input"
              placeholder="Email"
              v-model="email"
              @input="validateForm()"
            />
          </div>
          <button
            class="login-submit"
            type="submit"
            v-bind:disabled="!isValid && !autofilled"
            @click="submitForm($event)"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
const apiUrl = process.env.BASE_URL;

export default {
  data() {
    return {
      email: "",
      isValid: false,
      autofilled: false,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },

  methods: {
    loginClose() {
      this.$emit("loginClose");
    },

    validateForm() {
      this.isValid =
        this.email == "" ? "" : this.reg.test(this.email) ? true : false;
    },

    submitForm(e) {
      e.preventDefault();

      const form = document.getElementById("login");
      const fd = new FormData(form);

      fd.append("password[email]", this.email);

      fetch(`${apiUrl}/api/auth/passwords`, {
        method: "POST",
        body: fd,
      })
        .then((resp) => {
          if (resp.status === 201) {
            this.$emit("restoreComplete");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.login-title {
  margin-bottom: 56px;
  color: #1e2541;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

@media (max-width: 767px) {
  .login-title {
    font-size: 24px;
    line-height: 1;
  }
}

.login-subtitle {
  margin: -27px 0 45px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1e2541;
  text-align: center;
}

.login-fade {
  padding: 50px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(242, 242, 242, 0.85);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .login-fade {
    padding-top: 100px;
  }
}

.login {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 20px;
  padding: 58px 70px 52px;
  max-width: 456px;
  height: fit-content;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
}

@media (max-width: 991px) {
  .login {
    padding: 58px 20px 52px;
  }
}

.login-close {
  position: absolute;
  top: 36px;
  right: 36px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.login-form {
  width: 100%;
}

.input-wrapper {
  margin-bottom: 15px;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #d1d8dd;
  border-radius: 35px;
  overflow: hidden;
  z-index: 1;
}

.input-icon {
  margin-left: 31px;
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.input {
  padding: 17px 31px 17px 54px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border: none;
  outline: none;
  color: #3a4146;
}

.input::placeholder {
  color: #3a4146;
}

.back-button {
  position: absolute;
  top: 21px;
  left: 37px;
  display: flex;
  align-items: center;

  line-height: 22px;
  color: #3a4146;
  border: none;
  background: none;
  cursor: pointer;
}

.back-button svg {
  margin-right: 10px;
}

.login-submit {
  display: block;
  margin: 36px auto 0;
  width: 100%;
  height: 50px;
  color: #fff;
  background: linear-gradient(315deg, #1f32c7 0%, #3cd1f3 100%);
  border-radius: 23px;
  border: none;
  font-family: SF Pro Display;
  font-style: normal;
  font-size: 16px;
  cursor: pointer;
}

.login-submit.outer {
  padding: 15px 55px;
  width: auto;
}

.login-submit:disabled {
  opacity: 0.3;
  cursor: initial;
}

.circle {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.login-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
