<template>
    <div>
        <div class="content-wrapper">
            <HeaderMain v-if="currentTemplate" ref="header" :headerType="currentTemplate" />
            <component :is="currentTemplate">
                <router-view></router-view>
            </component>
        </div>
        <!-- <Toast
      v-if="showToast"
      icon="success"
      text="You are viewing our pre-release beta version right now. Please download the app in Google Play to enjoy our marketplace!"
      :timeout="10000"
      @toastClose="toastClose"
    /> -->
        <transition name="fade">
            <InfoToast v-if="toast.active" :type="toast.type" :text="toast.text" @alertClose="alertClose" />
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import defaultTemplate from './templates/default.vue';
import metamaskTemplate from './templates/metamask.vue';
import paymentTemplate from './templates/payment.vue';
import albumTemplate from './templates/album.vue';

import HeaderMain from './components/header.vue';
import Toast from '../scripts/components/toasts/toast.vue';
import InfoToast from '../scripts/components/toasts/infoToast.vue';

import accordion from './services/accordion';

import { detectIncognito } from 'detectincognitojs';

const token = localStorage.getItem('token');

export default {
    components: {
        defaultTemplate,
        metamaskTemplate,
        paymentTemplate,
        albumTemplate,
        HeaderMain,
        InfoToast,
        Toast,
    },

    data() {
        return {
            toastStatus: true,
        };
    },

    computed: {
        ...mapGetters('auth', ['isAuthenticated']),
        ...mapGetters(['toast']),

        currentTemplate() {
            return this.$route.meta.template;
        },

        showToast() {
            return this.toastStatus && process.env.NODE_ENV === 'production';
        },
    },

    methods: {
        alertClose() {
            const payload = {
                active: false,
                type: null,
                text: '',
            };

            this.$store.dispatch('setToast', payload);
        },

        toastClose() {
            this.toastStatus = false;
        },

        onStorageChange(event) {
            if (event.key === 'token') {
                window.location.reload();
            }
        },
    },

    mounted() {
        window.addEventListener('storage', this.onStorageChange);

        accordion();

        if (localStorage.getItem('pixchange_cookie')) {
            const pixelScript = document.createElement('script');
            pixelScript.src = '/public/pixel/script.js';
            document.head.appendChild(pixelScript);
        }
    },

    updated() {
        setTimeout(() => {
            const buttons = document.querySelectorAll(
                'button:not(.clipper-button):not(.toast-close):not(.remove-btn)'
            );

            buttons.forEach((item) => {
                item.addEventListener('click', function () {
                    item.style.transform = 'scale(0.95) translateZ(0)';

                    setTimeout(() => {
                        item.style.transform = 'scale(1) translateZ(0)';
                    }, 500);
                });
            });
        }, 1000);

        detectIncognito().then((result) => {
            if (result.browserName === 'Safari' && result.isPrivate && this.$route.meta.requiresAuth) {
                setInterval(() => {
                    const tokenExist = localStorage.getItem('token');

                    if (!tokenExist) {
                        this.$store.dispatch('auth/setLoginStay', true);
                        this.$store.dispatch('auth/setNoRedirect', true);
                    }
                }, 5000);
            }
        });
    },

    watch: {
        '$route.query.error'(value) {
            if (!token && value) {
                this.$store.dispatch('auth/setLogin', true);
            }
        },

        '$route.path'() {
            if (!sessionStorage.getItem('notScroll')) {
                window.scrollTo(0, 0);
            } else {
                sessionStorage.removeItem('notScroll');
                const anchor = document.getElementById('anchor');
                if (anchor) window.scrollTo(0, anchor.offsetHeight);
            }
        },
    },
};
</script>

<style scoped>
.content-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 100vh;
}
</style>
