const state = {
  nftCollections: [],
  nftCollected: [],
};

const mutations = {
  setNftCollections(state, nftCollections) {
    state.nftCollections = nftCollections;
  },

  setNftCollected(state, nftCollected) {
    state.nftCollected = nftCollected;
  },
};

const actions = {
  setNftCollections({ commit }, payload) {
    commit("setNftCollections", payload);
  },

  setNftCollected({ commit }, payload) {
    commit("setNftCollected", payload);
  },
};

const getters = {
  nftCollections: (state) => state.nftCollections,
  nftCollected: (state) => state.nftCollected,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
