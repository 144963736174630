<template slot-scope="slotData">
  <DefaultTemplate>
    <UserInfo
      id="anchor"
      :user="user"
      :profile="isProfilePage"
      :publicAlbums="publicAlbums"
      :privateAlbums="privateAlbums"
      :nftCollections="nftCollections"
      @userDataUpdate="getUser"
    />
    <slot />
    <PreloaderComponent v-if="!isLoaded" />
  </DefaultTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import albums from "../components/mixins/albums";

import DefaultTemplate from "./default.vue";
import UserInfo from "../components/ArtistsProfile/UserInfo.vue";
import PreloaderComponent from "../components/preloader.vue";

export default {
  mixins: [albums],

  components: {
    DefaultTemplate,
    UserInfo,
    PreloaderComponent,
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated", "currentUser"]),

    checkAlbumData() {
      return !this.$route.params.id && this.isAuthenticated;
    },
  },

  data() {
    return {
      isLoaded: false,
      dataIsGotten: false,
    };
  },

  methods: {
    async initialize() {
      await this.getData();
      this.isLoaded = true;
    },
  },

  async mounted() {
    await this.getUser();
    },

  watch: {
    "$route.name"() {
      this.getData();
      this.getUser();
    },

    checkAlbumData: {
      handler(value) {
        if (!this.dataIsGotten) {
          this.initialize();
          if (value) this.dataIsGotten = true;
        }
      },
      immediate: true,
    },
  },
};
</script>
