<template>
  <div class="login-fade">
    <div class="login">
      <button @click="inviteClose" class="login-close">
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.00342 2L20.6685 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M20.665 2L1.99993 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div class="login-form">
        <p class="login-title">Invite your friends!</p>
        <p class="login-subtitle">
          {{ this.currentUser.full_name }} has invited you to Pixchange!
        </p>
        <img
          class="invite-image"
          src="../../../../public/img/invitation.png"
          width="193"
          height="193"
          loading="lazy"
          alt="invitation"
        />
        <div class="invitation-bottom">
          <input
            type="text"
            ref="inviteInput"
            :value="inviteLink"
            readonly
            class="invite-link"
          />
          <div class="copy-link-wrapper">
            <div
              v-show="copyLink"
              class="copy-link"
              v-click-outside="copyLinkClose"
            >
              <div class="copy-link-inner">
                <p class="copy-title">
                  Copied to clipboard!
                </p>
              </div>
            </div>
            <button class="invite-copy" @click="copyLinkClick">
              Copy link
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ClickOutside from "vue-click-outside";

const apiUrl = process.env.BASE_URL;

import { deepLinkTransformMain } from "../../services/shortDeepLink";

export default {
  directives: {
    ClickOutside,
  },

  data() {
    return {
      copyLink: false,
      readyToClose: false,
      inviteLink: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
  },

  methods: {
    inviteClose() {
      this.$emit("inviteClose");
    },

    async invitationLinkCreate() {
      const { shortLink } = await deepLinkTransformMain(
        `${apiUrl}/user-invitation/${this.currentUser.id}`
      );

      this.inviteLink = shortLink;
    },

    copyLinkClick() {
      this.$refs.inviteInput.select();
      document.execCommand("copy");
      this.copyLink = true;
      setTimeout(() => {
        this.readyToClose = true;
      }, 500);
    },

    copyLinkClose(event) {
      if (this.readyToClose && !event.target.classList.contains("copy-input")) {
        this.copyLink = false;
        this.readyToClose = false;
      }
    },
  },

  mounted() {
    document.body.style.overflow = "hidden";
    this.invitationLinkCreate();
  },

  destroyed() {
    document.body.removeAttribute("style");
  },
};
</script>

<style scoped>
.login-title {
  margin-bottom: 56px;
  color: #1e2541;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}


@media (max-width: 767px) {
  .login-title {
    font-size: 24px;
    line-height: 1;
  }
}
.login-subtitle {
  margin: -27px 0 45px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #1e2541;
  text-align: center;
}

.login-fade {
  padding: 50px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(242, 242, 242, 0.85);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .login-fade {
    padding-top: 100px;
  }
}

.login {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 20px;
  padding: 58px 70px 52px;
  max-width: 774px;
  height: fit-content;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
}

@media (max-width: 991px) {
  .login {
    padding: 58px 20px 52px;
  }
}

.login-close {
  position: absolute;
  top: 36px;
  right: 36px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.back-button {
  position: absolute;
  top: 21px;
  left: 37px;
  display: flex;
  align-items: center;

  line-height: 22px;
  color: #3a4146;
  border: none;
  background: none;
  cursor: pointer;
}

.back-button svg {
  margin-right: 10px;
}

.login-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.invite-image {
  display: block;
  margin: 0 auto 51px;
}

.invitation-bottom {
  width: 369px;
  display: flex;
  align-items: center;
}

.invite-link {
  padding: 7px 12px;
  width: 246px;
  height: 29px;
  border: 1px solid #6d7d89;
  border-radius: 2px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  letter-spacing: -0.256px;
  color: #6d7d89;
}

.invite-copy {
  margin-left: 21px;
  width: 102px;
  height: 29px;
  border: none;
  background: linear-gradient(315deg, #1f32c7 0%, #3cd1f3 100%);
  border-radius: 23px;

  line-height: 19px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.copy-link-wrapper {
  position: relative;
}

.copy-link {
  position: absolute;
  bottom: 100%;
  left: 40px;
  width: max-content;
}

.copy-link-inner {
  padding: 20px 22px;
  position: relative;
}

.copy-link-inner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='312' height='126' viewBox='0 0 312 126' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_5551_2714)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M233.516 7.8737C232.333 6.05252 229.667 6.05252 228.484 7.8737L223.206 16.0003H20C14.4772 16.0003 10 20.4774 10 26.0003V102C10 107.523 14.4771 112 20 112H292C297.523 112 302 107.523 302 102V26.0003C302 20.4774 297.523 16.0003 292 16.0003H238.794L233.516 7.8737Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_5551_2714' x='0' y='0.507812' width='312' height='125.492' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.172549 0 0 0 0 0.568627 0 0 0 0 0.701961 0 0 0 0.3 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_5551_2714'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_5551_2714' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.copy-title {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.256px;
  position: relative;
  z-index: 1;
}
</style>
