<template>
  <div @dragover.prevent @drop.prevent>
    <div class="image-previews" @drop="dragFile">
      <div
        v-for="(item, index) in imagesData"
        :key="item.index"
        class="preview-item"
      >
        <div
          :class="{ error: item.error || !namesChecker[index].length }"
          class="preview-item-inner"
        >
          <button @click="imageRemove(index)" class="image-remove">
            <svg
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="31"
                height="31"
                rx="15.5"
                fill="url(#paint0_linear_6869_23016)"
              />
              <path
                d="M12.001 12L18.9997 18.9988"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M18.999 12L12.0003 18.9988"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_6869_23016"
                  x1="46.5"
                  y1="15.5"
                  x2="15.5"
                  y2="-15.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1F32C7" />
                  <stop offset="1" stop-color="#3CD1F3" />
                </linearGradient>
              </defs>
            </svg>
          </button>
          <div
            v-if="item.type.match('video')"
            class="video-wrapper"
            @mouseenter="autoplayOn"
            @mouseleave="autoplayOff"
          >
            <LazyVideo
              :src="item.src"
              :attrs="{ controls: false, loop: true, muted: true }"
              class="preview-image video"
            />
          </div>
          <img
            v-else
            :src="item.src"
            width="292"
            height="216"
            loading="lazy"
            class="preview-image"
          />
          <input
            v-model="namesChecker[index]"
            type="text"
            @input="editName($event, index, item)"
            @click="selectValue"
            class="image-name"
          />
        </div>
        <p v-if="item.error" class="error-text">
          {{ item.error }}
        </p>
        <p v-else-if="!namesChecker[index].length" class="error-text">
          Enter image name
        </p>
      </div>
      <div class="previews-bottom">
        <p class="note">
          Min res. 200x200px | Max file size 50MB | Max 30 sec. long | File
          formats PNG, GIF, JPEG, SVG, MP4
        </p>
        <v-button @click="focusInput" secondary>Add More Files</v-button>
        <v-button @click="saveFiles" :disabled="dataIsNotValid">Next</v-button>
      </div>
      <input
        id="uploadFile"
        ref="uploadFile"
        type="file"
        accept="image/gif, image/png, image/jpeg, image/svg+xml, video/mp4"
        multiple
        @change="uploadFile"
      />
    </div>
  </div>
</template>

<script>
import vButton from "../../../components/ui/v-button.vue";

export default {
  components: {
    vButton,
  },

  props: {
    imagesList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      imagesData: [],
      namesChecker: [],
    };
  },

  computed: {
    dataIsNotValid() {
      return !!(
        this.imagesList.find((item) => item.error) ||
        this.namesChecker.find((item) => !item.length)
      );
    },
  },

  methods: {
    focusInput() {
      this.$refs.uploadFile.click();
    },

    uploadFile(e) {
      this.$emit("addFiles", e.target.files);
    },

    dragFile(e) {
      this.$emit("addFiles", e.dataTransfer.files);
    },

    autoplayOn(event) {
      event.target.querySelector("video").play();
    },

    autoplayOff(event) {
      const target = event.target.querySelector("video");

      target.pause();
      target.currentTime = 0;
    },

    imageRemove(index) {
      this.namesChecker.splice(index, 1);

      this.$emit("imageRemove", index);
    },

    saveFiles() {
      this.$emit("saveFiles", this.imagesData);
    },

    fileRename(fileName, file) {
      const copiedFile = new File([file], fileName, {
        type: file.type,
        lastModified: file.lastModified,
      });

      const fr = new FileReader();

      fr.onload = () => {
        copiedFile.width = file.width;
        copiedFile.height = file.height;
        copiedFile.src = file.src;
        copiedFile.status = file.status;
      };

      file.type.match("image")
        ? fr.readAsDataURL(copiedFile)
        : fr.readAsArrayBuffer(copiedFile);

      return copiedFile;
    },

    editName(event, index, file) {
      setTimeout(() => {
        const fileName = event.target.value;

        if (fileName.length)
          this.imagesData[index] = this.fileRename(fileName, file);

        this.namesChecker[index] = fileName;
      }, 0);
    },

    selectValue(event) {
      event.target.select();
    },

    initializie() {
      this.imagesData = this.imagesList;
      this.namesChecker = this.imagesData.map((item) => {
        const newName = item.name
          .split(".")
          .slice(0, -1)
          .join(".");

        const newFileData = this.fileRename(newName, item);

        return newFileData.name;
      });
    },
  },

  mounted() {
    this.initializie();
  },

  watch: {
    imagesList() {
      this.initializie();
    },
  },
};
</script>

<style scoped>
.image-previews {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.preview-item {
  margin: 0 15px 28px;
  width: calc(50% - 30px);
  height: 25vw;
  max-height: 218px;
}

@media (max-width: 767px) {
  .preview-item {
    height: 36vw;
  }
}

.preview-item-inner {
  position: relative;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 17px;
  overflow: hidden;
}

.preview-item-inner.error,
.preview-item-inner.name-error {
  border-color: #ff0000;
}

.error-text {
  font-family: "SF Pro Display";
  color: #ff0000;
  margin-top: 5px;
  font-size: 14px;
}

.video-wrapper {
  height: 100%;
}

.preview-image {
  width: 100%;
  height: 100%;
  min-height: 150px;
  object-fit: cover;
}

.preview-image.video {
  cursor: wait;
}

.image-remove {
  padding: 0;
  width: 31px;
  height: 31px;
  background: none;
  border: none;
  position: absolute;
  top: 12px;
  right: 15px;
  cursor: pointer;
  z-index: 1;
}

.image-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f0f0f0;
  opacity: 0.9;
  border-radius: 0 0 15px 15px;
  color: #000;
  text-align: center;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 34px;
  border: none;
  outline: none;
}

.previews-bottom {
  margin: 0 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid #2c79db;
}

.note {
  margin: 29px auto 53px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #6d7d89;
}

#uploadFile {
  visibility: hidden;
  position: absolute;
}

@media (max-width: 767px) {
  .image-remove,
  .image-remove svg {
    width: 27px;
    height: 27px;
  }
}

@media (max-width: 425px) {
  .preview-item {
    margin: 0 15px 30px;
    width: calc(50% - 30px);
  }

  .previews-bottom {
    justify-content: center;
  }
}

::v-deep .button {
  margin-bottom: 20px;
  padding: 16px 10px;
  max-width: 142px;
  width: 100%;
}

@media (min-width: 375px) {
  ::v-deep .button:last-child {
    margin-left: auto;
  }
}

@media (max-width: 374px) {
  .previews-bottom {
    flex-direction: column;
    align-items: center;
  }
}
</style>
