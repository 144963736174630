import config from '../../../config/apps';
const getImageData = (data) => {
  let versions = Object.values(data.image.versions);
  versions.push(data.image);

  const filtered = versions.filter((version)=> {
    return !!version.size;
  });

  const sorted = filtered.sort((prev,next)=>{
    return prev.size > next.size;
  });

  const index = sorted.findIndex((el,ind)=>{
    return el.size < 8000000;
  });

  return sorted[1];
};

export const createArtTags = (data) => {

  const tags = [
    {
      tagName: 'meta',
      tagAttrValue: 'fb:app_id',
      tagAttr: 'property',
      content: config[process.env.NODE_ENV]
    },
    {
      tagName: 'meta',//meta
      tagAttr: 'property',
      tagAttrValue: 'og:image',
      content: getImageData(data).url
    },
    {
      tagName: 'meta',//metaTitle
      tagAttr: 'property',
      tagAttrValue: 'og:title',
      content: data.title ?  data.title: ''
    },
    {
      tagName: 'meta',//metaurl
      tagAttr: 'property',
      tagAttrValue: 'og:url',
      content: data.web_page_url
    },
    {
      tagName: 'meta',//metaWidth
      tagAttr: 'property',
      tagAttrValue: 'og:image:width',
      content: getImageData(data).width
    },
    {
      tagName: 'meta',//metaHeight
      tagAttr: 'property',
      tagAttrValue: 'og:image:height',
      content: getImageData(data).height
    },
    {
      tagName: 'meta',//metaType
      tagAttr: 'property',
      tagAttrValue: 'og:image:type',
      content: `image/${getImageData(data).url.split('.').pop()}`
    }];
  return tags;
};