<template>
  <div class="login-fade">
    <div class="login">
      <button @click="popupClose" class="login-close">
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.00342 2L20.6685 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M20.665 2L1.99993 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div class="login-form">
        <p class="login-title">
          Are you sure you want to logout?
        </p>
        <svg
          class="icon"
          width="66"
          height="81"
          viewBox="0 0 66 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.50146 5.75619e-05C2.48533 5.75619e-05 0 2.48539 0 5.50086V75.1819C0 78.1974 2.48533 80.6827 5.50146 80.6827H34.8408C35.332 80.6897 35.8046 80.4998 36.1545 80.1551C36.5043 79.8103 36.7006 79.3403 36.7006 78.8491C36.7006 78.3586 36.5043 77.8879 36.1545 77.5431C35.8046 77.1984 35.332 77.0085 34.8408 77.0155H5.50146C4.45389 77.0155 3.66791 76.2295 3.66791 75.182V5.50093C3.66791 4.45336 4.45392 3.66738 5.50146 3.66738H34.8408C35.332 3.67442 35.8046 3.48447 36.1545 3.13975C36.5043 2.79503 36.7006 2.32432 36.7006 1.83379C36.7006 1.34261 36.5043 0.872544 36.1545 0.527822C35.8046 0.183101 35.332 -0.00683567 34.8408 0.000188095L5.50146 5.75619e-05ZM43.8097 18.3077C43.0959 18.3768 42.4877 18.8564 42.2536 19.5344C42.0196 20.2116 42.2018 20.9637 42.7205 21.4594L59.7675 38.5064H18.3371C18.2801 38.5038 18.2226 38.5038 18.1657 38.5064C17.679 38.5288 17.2217 38.7443 16.8936 39.1037C16.5662 39.4638 16.3948 39.9396 16.4171 40.4257C16.4402 40.9124 16.655 41.3696 17.0151 41.6977C17.3752 42.0252 17.851 42.1966 18.3371 42.1735H59.7675L42.7205 59.2205C42.315 59.5473 42.0681 60.0308 42.04 60.5501C42.0125 61.0701 42.2063 61.5772 42.5747 61.9456C42.9424 62.3133 43.4496 62.5078 43.9695 62.4796C44.4895 62.4521 44.973 62.2046 45.2992 61.7992L65.47 41.6283C66.1767 40.914 66.1767 39.764 65.47 39.0497L45.2992 18.8789C44.9564 18.5162 44.4805 18.3097 43.981 18.3058C43.9241 18.3033 43.8666 18.3033 43.8096 18.3058L43.8097 18.3077Z"
            fill="black"
          />
        </svg>
        <div class="buttons">
          <button class="main-btn" @click="popupClose">Cancel</button>
          <button class="secondary-btn" @click="logoutConfirm">Logout</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    popupClose() {
      this.$emit("popupClose");
    },

    async logoutConfirm() {
      try {
        await this.$store.dispatch("auth/logOut");
        
        if (this.$route.name !== "HomePage") this.$router.push("/");

        localStorage.setItem("logOut", 1);

        this.$emit("popupClose");
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
};
</script>

<style scoped>
.login-title {
  margin-bottom: 36px;
  color: #1e2541;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

@media (max-width: 767px) {
  .login-title {
    font-size: 24px;
    line-height: 1;
  }
}

.login-fade {
  padding: 50px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
}

.login {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 20px;
  padding: 75px 70px 52px;
  max-width: 774px;
  height: fit-content;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
}

.login-close {
  position: absolute;
  top: 36px;
  right: 36px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.login-form {
  width: 100%;
}

.login-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.icon {
  display: block;
  margin: 0 auto;
}

.buttons {
  margin-top: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-btn {
  margin-right: 29px;
  background: linear-gradient(264.92deg, #1c23c3 10.3%, #39c0ee 110.14%);
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
  border-radius: 23px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.3168px;
  color: #fff;
  text-shadow: 0px 1px 0px #386dbd;
  border: none;
  width: 148px;
  height: 44px;
  cursor: pointer;
}

.secondary-btn {
  margin-left: 29px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
  border-radius: 23px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.256px;
  color: #6d7d89;
  border: none;
  background: #fff;
  width: 148px;
  height: 44px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .login-fade {
    padding-top: 100px;
  }

  .login {
    padding: 75px 20px 52px;
  }
}
</style>
