
import config from '../../../config/apps';
const getGiphyTags = (data) => {
  let flag = false; 
  const getImageData = (data) => {

    if(data.image.versions.fixed_extra_large && data.image.versions.fixed_extra_large.size < 8000000){
      return data.image.versions.fixed_extra_large;
    }
    if(data.image.versions.fixed_large && data.image.versions.fixed_large.size < 8000000){
      return data.image.versions.fixed_large;
    } else {
      flag = true;
      return data.image.giphy_versions['480w_still'];
    }

  };


  const imageData = getImageData(data);

  const tags = [
    {
      tagName: 'meta',
      tagAttrValue: 'fb:app_id',
      tagAttr: 'property',
      content: config[process.env.NODE_ENV]
    },
    {
      tagName: 'meta',//meta
      tagAttr: 'property',
      tagAttrValue: 'og:image',
      content: imageData.url
    },
    {
      tagName: 'meta',//metaTitle
      tagAttr: 'property',
      tagAttrValue: 'og:title',
      content: data.title ? data.title : ''
    },
    {
      tagName: 'meta',//metaurl
      tagAttr: 'property',
      tagAttrValue: 'og:url',
      content: flag ? data.web_page_url : imageData.url 
    },
    {
      tagName: 'meta',//metaWidth
      tagAttr: 'property',
      tagAttrValue: 'og:image:width',
      content: imageData.width // 3
    },
    {
      tagName: 'meta',//metaHeight
      tagAttr: 'property',
      tagAttrValue: 'og:image:height',
      content: imageData.height // 4
    },
    {
      tagName: 'meta',//metaType
      tagAttr: 'property',
      tagAttrValue: 'og:image:type',
      content: flag ? 'image/jpg' :'image/gif'
    }
  ];
  return tags;

};

const getVideoWithSoundTags = (data)=> {
  const head = document.querySelector('head');
  const title = head.querySelector('title');
  title.innerHTML = 'Pixchange';
  const tags = [
    {
      tagName: 'meta',
      tagAttrValue: 'fb:app_id',
      tagAttr: 'property',
      content: config[process.env.NODE_ENV]
    },
    {
      tagName: 'meta',
      tagAttr: 'property',
      tagAttrValue: 'og:type',
      content: 'video.movie'
    },
    {
      tagName: 'meta',
      tagAttr: 'property',
      tagAttrValue: 'og:title',
      content: data.title ? data.title : ''
    },
    {
      tagName: 'meta',
      tagAttr: 'property',
      tagAttrValue: 'og:url',
      content: data.web_page_url
    },
    {
      tagName: 'meta',
      tagAttr: 'property',
      tagAttrValue: 'og:image',
      content: data.image.versions.fixed_extra_large.preview_jpeg ? data.image.versions.fixed_extra_large.preview_jpeg : data.image.versions.fixed_large.preview_jpeg
    },
    {
      tagName: 'meta',
      tagAttr: 'property',
      tagAttrValue: 'og:image',
      content: data.image.versions.fixed_large.url
    }
  ];
  return tags;
};

const getTags = (data) => {
  let isGif = false;
  const getStillPreviewImage = (data)=> {
    isGif = false;
    const versions = Object.values(data.image.versions);
    const sorted = versions.filter(el => {
      return !!el.preview_jpeg_size;
    }).sort((prev, next) => {
      return prev.preview_jpeg_size > next.preview_jpeg_size;
    });
    const index = sorted.findIndex((el, ind) => {
      return el.preview_jpeg_size < 8000000;
    });
    return sorted[index];
  };
  const getVideoImageData = (data)=> {
    if(data.image.versions.fixed_large.size){
      isGif = true;
      return data.image.versions.fixed_large.size < 8000000 ? data.image.versions.fixed_large : getStillPreviewImage(data);
    } else {
      return getStillPreviewImage(data);
    }
  };

  const imageData = getVideoImageData(data);
  const tags = [
    {
      tagName: 'meta',//meta
      tagAttr: 'property',
      tagAttrValue: 'og:image',
      content: isGif ? imageData.url : imageData.preview_jpeg
    },
    {
      tagName: 'meta',//metaTitle
      tagAttr: 'property',
      tagAttrValue: 'og:title',
      content: data.title ? data.title : ''
    },
    {
      tagName: 'meta',//metaurl
      tagAttr: 'property',
      tagAttrValue: 'og:url',
      content: isGif ? imageData.url : data.web_page_url 
    },
    {
      tagName: 'meta',//metaWidth
      tagAttr: 'property',
      tagAttrValue: 'og:image:width',
      content: imageData.width // 3
    },
    {
      tagName: 'meta',//metaHeight
      tagAttr: 'property',
      tagAttrValue: 'og:image:height',
      content: imageData.height // 4
    },
    {
      tagName: 'meta',//metaType
      tagAttr: 'property',
      tagAttrValue: 'og:image:type',
      content: isGif ? 'image/gif': 'image/jpeg'
    }
  ];
  return tags;
};

export const createGifTags = (data) => {
  if (data.provider === 'giphy') {
    return getGiphyTags(data);
  } else if(data.audio) {
    return getVideoWithSoundTags(data);
  }
  else {
    return getTags(data);
  }
};