<template>
  <button
    v-if="!isLink"
    :disabled="disabled"
    :class="{ secondary, small }"
    class="button"
    @click="$emit('click')"
  >
    <slot />
  </button>
  <a
    v-else
    :href="href"
    target="_blank"
    :disabled="disabled"
    :class="{ secondary, small }"
    class="button"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    isLink: {
      type: Boolean,
      default: false,
    },

    href: {
      type: String,
      default: "#",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    secondary: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.button {
  display: block;
  padding: 16px 40px;
  font-weight: 500;
  background: linear-gradient(315deg, #1f32c7 0%, #3cd1f3 100%);
  box-shadow: 0px 4px 10px rgba(49, 146, 226, 0.3);
  border-radius: 23px;
  line-height: 18px;
  color: #fff;
  border: none;
  text-align: center;
  cursor: pointer;
}

.button[disabled] {
  opacity: 0.5;
  cursor: initial;
}

.secondary {
  color: #000;
  background: #fff;
}

.small {
  padding: 8px 40px;
  line-height: 19px;
}

@media (max-width: 425px) {
  .button {
    padding: 16px 20px;
  }
}
</style>
