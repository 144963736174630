<template>
  <div :class="{ full: isFull }" @click="nftItemClick" class="nft-collection">
    <span v-if="isFull" class="nft-label">Collection Full</span>
    <div
      :class="{ empty: !collection.profile_image }"
      class="nft-collection-inner"
    >
      <img
        v-if="collection.profile_image"
        :src="collection.profile_image.url"
        width="290"
        height="222"
        loading="lazy"
        :alt="`collection ${collection.title}`"
        class="nft-collection-image"
      />
    </div>
    <div class="nft-collection-bottom">
      <p class="nft-title">
        {{ collection.title }}
      </p>
      <p class="nft-status">
        {{ nftStatus }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collection: {
      type: Object,
      required: true,
    },
  },

  computed: {
    nftStatus() {
      return this.collection.nft_status === "minted"
        ? this.collection.erc_type === "erc_1155"
          ? "With Copies"
          : "Unique"
        : "Draft";
    },

    isFull() {
      return this.collection.max_amount === this.collection.minted_count;
    },
  },

  methods: {
    openCollection() {
      this.$router.push(`/collections/${this.collection.id}`);
    },

    nftItemClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.nft-collection {
  padding-top: 75%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.2));
  border-radius: 15px;
  cursor: pointer;
  overflow: hidden;
}

@media (max-width: 767px) {
  .nft-collection {
    padding-top: 100%;
  }
}

.nft-collection-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.nft-label {
  padding: 9px;
  position: absolute;
  top: 31px;
  left: 0;
  color: #fff;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  background: #9fa0a1;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.2));
  opacity: 0.8;
}

.nft-collection-inner.empty {
  background-image: url("../../../../public/img/metamask/nft-placeholder.svg");
  background-repeat: no-repeat;
  background-position: center calc(75% - 66px);
}

.full .nft-collection-inner {
  opacity: 0.2;
}

.nft-collection-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 11px;
  width: 100%;
  background: #f1f2f4;
  opacity: 0.8;
  box-sizing: border-box;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.nft-title {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.256px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nft-status {
  color: #2655d1;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.256px;
}

.full .nft-title {
  color: #000;
  opacity: 0.5;
}

.full .nft-status {
  color: #b2b3b4;
}

.nft-collection-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.nft-collection-edit {
  position: absolute;
  top: 11px;
  right: 11px;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.2));
  border: none;
  border-radius: 100%;
  cursor: pointer;
}

@media (max-width: 425px) {
  .nft-collection-bottom {
    padding: 8px;
  }

  .nft-title {
    margin-bottom: 3px;
    font-size: 13px;
    line-height: 1;
  }

  .nft-status {
    font-size: 12px;
    line-height: 1;
  }
}
</style>
