import axios from "axios";

const apiUrl = process.env.BASE_URL;
const types = ["gif", "sticker", "art", "ar_effects"];

const state = {
  processing: false,
  spotlights: [],
  spotlightsOrder: [],
  categories: [],
  subCategories: [],
  subCategoryImages: {
    gif: [],
    sticker: [],
    art: [],
  },
  giphyImages: [],
  suggestions: [],
  searchResult: {
    gif: [],
    sticker: [],
    art: [],
  },
  searchedUsers: {
    access: true,
    users: [],
  },
  searchValue: "",
  startSearch: false,
  filter: {
    active: false,
    min: 0,
    max: 500000,
    nft: "",
  },
  breadcrumbs: {
    category: "",
    subcategory: "",
    categoryId: null,
  },
  pageStatus: "default",
  activeImage: {},
};

const mutations = {
  setProcessing(state, processing) {
    state.processing = processing;
  },

  setSpotlights(state, spotlights) {
    state.spotlights = spotlights;
  },

  setSpotlightsOrder(state, spotlightsOrder) {
    state.spotlightsOrder = spotlightsOrder;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },

  setSubCategories(state, subCategories) {
    state.subCategories = subCategories;
  },

  setFirstGiphy(state, firstGiphy) {
    state.giphyImages = firstGiphy;
  },

  setFirstImages(state, firstImages) {
    state.subCategoryImages = firstImages;
  },

  setLazyFetchGiphy(state, lazyGiphy) {
    state.giphyImages.push(...lazyGiphy);
  },

  setLazyFetchImages(state, lazyFetchImages) {
    state.subCategoryImages[lazyFetchImages.type] = [
      ...state.subCategoryImages[lazyFetchImages.type],
      ...lazyFetchImages.collection,
    ];
  },

  setSearchResult(state, searchResult) {
    state.searchResult = searchResult;
  },

  setSearchedUsers(state, searchedUsers) {
    state.searchedUsers.users = searchedUsers;
  },

  setUsersSearchAccess(state, access) {
    state.searchedUsers.access = access;
  },

  setSearchValue(state, searchValue) {
    state.searchValue = searchValue;
  },

  setStartSearch(state, startSearch) {
    state.startSearch = startSearch;
  },

  setBreadcrumbs(state, breadcrumb) {
    state.breadcrumbs[breadcrumb.type] = breadcrumb.name;
  },

  clearBreadcrumbs(state, clearBreadcrumbs) {
    state.breadcrumbs = clearBreadcrumbs;
  },

  setPageStatus(state, pageStatus) {
    state.pageStatus = pageStatus;
  },

  setActiveImage(state, activeImage) {
    state.activeImage = activeImage;
  },

  setFilter(state, filter) {
    state.filter[filter.type] = filter.value;
  },

  setSuggestions(state, suggestions) {
    state.suggestions = suggestions;
  },
};

const actions = {
  setProcessing({ commit }, payload) {
    commit("setProcessing", payload);
  },

  async getSpotlights({ commit }, payload) {
    try {
      window.scrollTo(0, 0);

      const { data } = await axios.get(
        `${apiUrl}/api/spotlights?page=${payload}&per_page=13`
      );

      const sortedOrder = data.collection.filter(
        (item) => item.kind !== "users"
      );

      commit("setSpotlightsOrder", sortedOrder);

      const spotlightData = data.collection;

      const orderArray = spotlightData.map((item) => item.id);

      const spotlights = {
        data: [],
        totalPages: data.total_pages,
      };

      async function getSpotlightData(item) {
        const { data } = await axios.get(
          `${apiUrl}/api/spotlights/${item.id}/images?per_page=40`
        );

        if (item.kind !== "users") {
          spotlights.data.push({
            id: item.id,
            name: item.name,
            images: data.collection,
          });

          spotlights.data = spotlights.data.sort(
            (a, b) => orderArray.indexOf(a.id) - orderArray.indexOf(b.id)
          );
        }
      }

      const promises = spotlightData.map((item) => getSpotlightData(item));

      Promise.all(promises);

      commit("setSpotlights", spotlights);
    } catch (error) {
      console.log(error);
    }
  },

  clearSpotlights({ commit }) {
    commit("setSpotlights", []);
  },

  async getCategories({ commit }) {
    try {
      const getCategoriesData = (page = 1) => {
        return axios.get(`${apiUrl}/api/categories?page=${page}`);
      };

      const { data } = await getCategoriesData();

      const categoriesData = data.collection;

      if (data.total_pages > 1) {
        for (let i = 2; i <= data.total_pages; i++) {
          const { data } = await getCategoriesData(i);

          categoriesData.push(data.collection);
        }
      }

      const cateogiresWithoutSvg = categoriesData.filter(
        (item) => item.id !== 30
      );

      commit("setCategories", cateogiresWithoutSvg);
    } catch (error) {
      console.log(error);
    }
  },

  async getSubCategories({ commit }, payload) {
    try {
      const getSubCategoriesData = (page = 1) => {
        return axios.get(
          `${apiUrl}/api/categories/${payload}/subcategories?page=${page}`
        );
      };

      const { data } = await getSubCategoriesData();

      const subCategoriesData = data.collection;

      if (data.total_pages > 1) {
        for (let i = 2; i <= data.total_pages; i++) {
          const { data } = await getSubCategoriesData(i);

          subCategoriesData.push(data.collection);
        }
      }

      commit("setSubCategories", subCategoriesData);
    } catch (error) {
      console.log(error);
    }
  },

  imagesClear({ commit }) {
    commit("setFirstImages", {
      gif: [],
      sticker: [],
      art: [],
    });
  },

  giphyClear({ commit }) {
    commit("setFirstGiphy", []);
  },

  async getFirstGiphy({ commit }, payload) {
    try {
      const collection = [];

      const url = `${apiUrl}/api/images?per_page=24&can_process_nft=true&provider=giphy`;

      const noSymbolsSearch = payload.search
        ? payload.search.replace(/\W+/g, "*")
        : "";

      const searchPart = payload.search
        ? `&q=${noSymbolsSearch}`
        : payload.subcategoryName
          ? `&q=${payload.subcategoryName}`
          : "";
      const minPricePart = payload.minPrice
        ? `&price_from=${payload.minPrice}`
        : "";
      const maxPricePart = payload.maxPrice
        ? `&price_to=${payload.maxPrice}`
        : "";
      const nftPart = payload.nft ? `&search_nft_only=${payload.nft}` : "";

      const request = `${url}${searchPart}${minPricePart}${maxPricePart}${nftPart}&type=type`;

      async function getImagesData(type) {
        const { data } = await axios.get(request.replace("=type", `=${type}`));

        const length = Math.ceil(data.collection.length / 6);

        for (let i = 0; i < length; i++) {
          const spliced = data.collection.splice(0, 6);

          collection.push(spliced);
        }
      }

      await getImagesData("gif");

      commit("setFirstGiphy", collection);
    } catch (error) {
      console.log(error);
    }
  },

  async getFirstImages({ commit }, payload) {
    try {
      const collection = {};

      const url = `${apiUrl}/api/images?per_page=24&can_process_nft=true&provider=pixchange`;

      const noSymbolsSearch = payload.search
        ? payload.search.replace(/\W+/g, "*")
        : "";

      const searchPart = payload.search ? `&q=${noSymbolsSearch}` : "";
      const subcategoryPart = payload.subcategory
        ? `&subcategory_id=${payload.subcategory}`
        : "";
      const minPricePart =
        payload.minPrice && payload.minPrice > 0
          ? `&price_from=${payload.minPrice}`
          : "";
      const maxPricePart =
        payload.maxPrice && payload.maxPrice > 0
          ? `&price_to=${payload.maxPrice}`
          : "";
      const nftPart = payload.nft ? `&search_nft_only=${payload.nft}` : "";

      const request = `${url}${searchPart}${subcategoryPart}${minPricePart}${maxPricePart}${nftPart}&type=type`;

      async function getImagesData(type) {
        const { data } = await axios.get(request.replace("=type", `=${type}`));

        const length = Math.ceil(data.collection.length / 6);
        collection[type] = [];

        for (let i = 0; i < length; i++) {
          const spliced = data.collection.splice(0, 6);

          collection[type].push(spliced);
        }
      }

      const promises = types.map((item) => getImagesData(item));

      await Promise.all(promises);

      commit("setFirstImages", collection);
    } catch (error) {
      console.log(error);
    }
  },

  async getLazyGiphyImages({ commit }, payload) {
    try {
      const url = `${apiUrl}/api/images?can_process_nft=true&provider=giphy`;

      const noSymbolsSearch = payload.search
        ? payload.search.replace(/\W+/g, "*")
        : "";

      const searchPart = payload.search
        ? `&q=${noSymbolsSearch}`
        : payload.subcategoryName
          ? `&q=${payload.subcategoryName}`
          : "";
      const minPricePart = payload.minPrice
        ? `&price_from=${payload.minPrice}`
        : "";
      const maxPricePart = payload.maxPrice
        ? `&price_to=${payload.maxPrice}`
        : "";
      const nftPart = payload.nft ? `&search_nft_only=${payload.nft}` : "";
      const typePart = payload.type ? `&type=${payload.type}` : "";
      const pagePart = payload.page ? `&page=${payload.page}` : "";

      const request = `${url}${searchPart}${minPricePart}${maxPricePart}${nftPart}${typePart}${pagePart}&per_page=24`;

      const { data } = await axios.get(request);

      const length = Math.ceil(data.collection.length / 6);
      const collection = [];

      for (let i = 0; i < length; i++) {
        const spliced = data.collection.splice(0, 6);

        collection.push(spliced);
      }

      commit("setLazyFetchGiphy", collection);

      return collection;
    } catch (error) {
      console.log(error);
    }
  },

  async getLazyFetchImages({ commit }, payload) {
    try {
      const url = `${apiUrl}/api/images?can_process_nft=true&provider=pixchange`;

      const noSymbolsSearch = payload.search
        ? payload.search.replace(/\W+/g, "*")
        : "";

      const searchPart = payload.search ? `&q=${noSymbolsSearch}` : "";
      const subcategoryPart = payload.subcategory
        ? `&subcategory_id=${payload.subcategory}`
        : "";
      const minPricePart = payload.minPrice
        ? `&price_from=${payload.minPrice}`
        : "";
      const maxPricePart = payload.maxPrice
        ? `&price_to=${payload.maxPrice}`
        : "";
      const nftPart = payload.nft ? `&search_nft_only=${payload.nft}` : "";
      const typePart = payload.type ? `&type=${payload.type}` : "";
      const providerPart = payload.type === "gif" ? "&provider=pixchange" : "";
      const pagePart = payload.page ? `&page=${payload.page}` : "";

      const request = `${url}${searchPart}${subcategoryPart}${minPricePart}${maxPricePart}${nftPart}${typePart}${providerPart}${pagePart}&per_page=24`;

      const { data } = await axios.get(request);

      const length = Math.ceil(data.collection.length / 6);
      const collection = [];

      for (let i = 0; i < length; i++) {
        const spliced = data.collection.splice(0, 6);

        collection.push(spliced);
      }

      const commitData = {
        type: payload.type,
        collection,
      };

      commit("setLazyFetchImages", commitData);

      return commitData.collection;
    } catch (error) {
      console.log(error);
    }
  },

  async getActiveImage({ commit }, payload) {
    try {
      const token = localStorage.getItem("token");

      if (token) {
        const { data } = await axios.get(`${apiUrl}/api/images/${payload.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        commit("setActiveImage", data);
      } else {
        const { data } = await axios.get(`${apiUrl}/api/images/${payload.id}`);

        commit("setActiveImage", data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async search({ commit }, payload) {
    try {
      const searchCollection = {};

      async function getSearchData(search, type) {
        const noSymbolsSearch = search.replace(/\W+/g, "*");

        let requestUrl = `${apiUrl}/api/images?per_page=12&q=${noSymbolsSearch}&type=${type}&provider=pixchange&can_process_nft=true`;

        const response = await axios.get(requestUrl);

        if (type === "gif" && response.data.collection.length < 12) {
          const giphyPerPage = 12 - response.data.collection.length;
          requestUrl = `${apiUrl}/api/images?per_page=${giphyPerPage}&q=${noSymbolsSearch}&type=${type}&provider=giphy&can_process_nft=true`;

          const giphyResponse = await axios.get(requestUrl);

          response.data.collection.push(...giphyResponse.data.collection);
        }

        searchCollection[type] = response.data.collection;
      }

      const promises = types.map((item) => getSearchData(payload, item));

      await Promise.all(promises);

      commit("setSearchResult", searchCollection);
    } catch (error) {
      console.log(error);
    }
  },

  usersSearchAccess({ commit }, payload) {
    commit("setUsersSearchAccess", payload);
  },

  async usersSearch({ commit }, payload) {
    try {
      const token = localStorage.getItem("token");

      const collection = [];

      const getUsersSearchData = async (page) => {
        const { data } = await axios.post(
          `${apiUrl}/api/users/search`,
          { page },
          {
            params: {
              query: payload,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        collection.push(data.collection);

        return data.total_pages;
      };

      const pagesCount = await getUsersSearchData(1);

      if (pagesCount > 1) {
        const promises = [];

        for (let i = 2; i <= pagesCount; i++) {
          promises.push(getUsersSearchData(i));
        }

        await Promise.all(promises);
      }

      commit("setSearchedUsers", collection);
    } catch (error) {
      console.log(error);
    }
  },

  searchClear({ commit }) {
    commit("setSearchResult", {
      gif: [],
      sticker: [],
      art: [],
    });

    // commit("setSearchValue", "");
  },

  setSearchValue({ commit }, payload) {
    commit("setSearchValue", payload);
  },

  setPageStatus({ commit }, payload) {
    commit("setPageStatus", payload);
  },

  setBreadcrumbs({ commit }, payload) {
    commit("setBreadcrumbs", payload);
  },

  clearBreadcrumbs({ commit }) {
    commit("clearBreadcrumbs", {
      category: "",
      subcategory: "",
      categoryId: null,
    });
  },

  setFilter({ commit }, payload) {
    commit("setFilter", payload);
  },

  setStartSearch({ commit }, payload) {
    commit("setStartSearch", payload);
  },

  async userFollow({}, payload) {
    try {
      const token = localStorage.getItem("token");

      await axios.post(
        `${apiUrl}/api/follows`,
        { followed_id: payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  },

  async userUnfollow({}, payload) {
    try {
      const token = localStorage.getItem("token");

      await axios.delete(`${apiUrl}/api/follows/${payload}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  },

  async getSuggestions({ commit }, pyalod) {
    try {
      const token = localStorage.getItem("token");

      const { data } = await axios.get(
        `${apiUrl}/api/suggestions?q=${pyalod}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      commit("setSuggestions", data.collection);
    } catch (error) {
      console.log(error);
    }
  },

  async addImageToAlbum({}, payload) {
    try {
      const token = localStorage.getItem("token");

      const result = await axios.post(
        `${apiUrl}/api/albums/new_image`,
        { album_image: payload.albumImage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      console.log(error);
    }
  },

  async moveImageToAlbum({}, payload) {
    try {
      const token = localStorage.getItem("token");

      const result = await axios.patch(
        `${apiUrl}/api/albums/${payload.albumId}/images/${payload.id}`,
        {
          album_image: {
            album_id: payload.albumImage.album_id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return result;
    } catch (error) {
      console.log(error);
    }
  },

  async addToFavourites({}, payload) {
    try {
      const token = localStorage.getItem("token");

      await axios.post(
        `${apiUrl}/api/albums/favourites`,
        { album_image: payload.albumImage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  },

  async moveImageToTops({}, payload) {
    try {
      const token = localStorage.getItem("token");

      await axios.post(
        `${apiUrl}/api/albums/tops`,
        { album_image: payload.albumImage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  },

  async removeImageFromTops({}, payload) {
    try {
      const token = localStorage.getItem("token");

      await axios.delete(`${apiUrl}/api/albums/tops/${payload}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  },

  async deleteImageFromAlbum({}, payload) {
    try {
      const token = localStorage.getItem("token");

      await axios.delete(
        `${apiUrl}/api/albums/${payload.album_id}/images/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  },

  clearSuggestions({ commit }) {
    commit("setSuggestions", []);
  },
};

const getters = {
  processing: (state) => state.processing,
  spotlights: (state) => state.spotlights,
  spotlightsOrder: (state) => state.spotlightsOrder,
  categories: (state) => state.categories,
  subCategories: (state) => state.subCategories,
  subCategoryImages: (state) => state.subCategoryImages,
  giphyImages: (state) => state.giphyImages,
  suggestions: (state) => state.suggestions,
  activeImage: (state) => state.activeImage,
  searchResult: (state) => state.searchResult,
  searchedUsers: (state) => state.searchedUsers,
  searchValue: (state) => state.searchValue,
  startSearch: (state) => state.startSearch,
  breadcrumbs: (state) => state.breadcrumbs,
  pageStatus: (state) => state.pageStatus,
  filter: (state) => state.filter,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
