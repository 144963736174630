<template>
  <div class="preloader" :class="isAbsolute ? 'is-absolute' : ''">
    <div class="loader">
      <img src="/public/img/loader-outer.png" alt="" class="loader-outer" />
      <img src="/public/img/loader-inner.png" alt="" class="loader-inner" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["isVisible", "isAbsolute"],
};
</script>

<style>
.preloader.is-absolute {
  position: absolute;
}
</style>
