<template>
  <div :class="{ colored }" class="page-content">
    <slot />
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from "../../scripts/components/footer.vue";

export default {
  components: {
    FooterComponent,
  },

  computed: {
    colored() {
      return this.$route.meta.colored;
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.colored {
  background: #f5f7f9;
}
</style>
