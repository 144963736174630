import axios from "axios";

const apiUrl = process.env.BASE_URL;
const token = localStorage.getItem("token");

const NftService = {
  getTokenOrders(tokenId, owned = false, authorization = true) {
    const apiEndpoint = `${apiUrl}/api/orders/token/${tokenId}?owned=${owned}`;

    if (authorization)
      return axios.get(`${apiEndpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

    return axios.get(`${apiEndpoint}`);
  },

  getTransactionStatus(externalId) {
    return axios.get(
      `${apiUrl}/api/transactions/transaction_status?transaction_external_id=${externalId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
};

export default NftService;
