<template>
  <div class="dropdown">
    <span class="link" @click="dropdownClick" v-click-outside="dropdownHide">
      {{ title }}
      <svg
        class="angle"
        :class="{ rotated: menu }"
        width="9"
        height="5"
        viewBox="0 0 9 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-1.92462e-07 0.596986C-1.85782e-07 0.749809 0.0567358 0.902539 0.170118 1.01908L4.04299 5L7.91586 1.01908C8.14263 0.785993 8.14263 0.407978 7.91586 0.174887C7.6891 -0.0582962 7.32134 -0.0582962 7.09458 0.174887L4.04299 3.31161L0.991404 0.174887C0.764639 -0.0582959 0.396883 -0.0582959 0.170118 0.174887C0.0567358 0.291433 -1.99142e-07 0.444164 -1.92462e-07 0.596986Z"
          fill="black"
        />
      </svg>
    </span>
    <ul v-show="menu" class="dropdown-menu">
      <slot />
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      menu: false,
    };
  },

  methods: {
    dropdownClick() {
      this.menu = !this.menu;
    },

    dropdownHide() {
      this.menu = false;
    }
  },
};
</script>

<style scoped>
.dropdown {
  margin-left: 48px;
  position: relative;
}

.link {
  display: flex;
  align-items: center;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000;
  text-decoration: none;
  cursor: pointer;
    transition: 250ms ease;
  border-bottom: 1px solid transparent;
}

.link:hover {
  color: #3A48ED;
  border-color: #3A48ED;
}


.angle {
  margin-left: 8px;
  transition: transform 250ms ease;
}

.angle.rotated {
  transform: rotate(180deg);
}

.dropdown-menu {
  margin-top: 18px;
  position: absolute;
  left: -10px;
  top: 100%;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 10px 10px;
}
</style>
