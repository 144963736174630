import MobileDetect from "mobile-detect";

export const mobileDetect = () => {
  const md = new MobileDetect(window.navigator.userAgent);

  return md.mobile() || md.tablet();
};

export const safariDetect = () => {
  const md = new MobileDetect(window.navigator.userAgent);

  return md.userAgent() === "Safari";
};

export const isIosSafari = () => {
  const md = new MobileDetect(window.navigator.userAgent);

  return (
    (md.mobile() || md.tablet()) &&
    md.os() !== "AndroidOS" &&
    md.userAgent() === "Safari"
  );
};
