<template>
  <div v-click-outside="menuHide" class="dots-menu-wrapper">
    <button class="dots-button" @click="menuToggle">
      <svg
        width="13"
        height="3"
        viewBox="0 0 13 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="1.5" cy="1.5" r="1.5" fill="#6D7D89" />
        <circle cx="6.5" cy="1.5" r="1.5" fill="#6D7D89" />
        <circle cx="11.5" cy="1.5" r="1.5" fill="#6D7D89" />
      </svg>
    </button>
    <ul v-show="menuActive" class="dots-menu-inner">
      <div @click="menuHide" class="dots-menu-content"><slot /></div>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside,
  },

  data() {
    return {
      menuActive: false,
    };
  },

  methods: {
    menuHide() {
      this.menuActive = false;
    },

    menuToggle() {
      this.menuActive = !this.menuActive;

      this.$emit("menuToggle");
    },
  },
};
</script>

<style scoped>
.dots-menu-wrapper {
  position: absolute;
  top: 15px;
  right: 19px;
  z-index: 13;
}

.dots-button {
  position: relative;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: #fff;
  border: none;
  border-radius: 100%;
  z-index: 1;
  cursor: pointer;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.2));
}

@media (max-width: 991px) {
  .dots-menu-wrapper {
    top: 11px;
    right: 9px;
  }

  .dots-button {
    width: 24px;
    height: 24px;
  }
}

.dots-button svg {
  position: relative;
  z-index: 1;
}

.dots-button.no-pointer {
  pointer-events: none;
}

.dots-menu-inner {
  position: relative;
  left: 9px;
  margin-top: 20px;
  min-width: 204px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(44, 145, 179, 0.3);
  border-radius: 10px;
  list-style-type: none;
}

.dots-menu-inner:before {
  content: url("data:image/svg+xml,%3Csvg width='46' height='42' viewBox='0 0 46 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_10257_29359)'%3E%3Cpath d='M20.5199 7.75762C21.7105 6.0081 24.2895 6.0081 25.4801 7.75762L35.3981 22.3312C36.7535 24.3228 35.3271 27.019 32.918 27.019L13.082 27.019C10.6729 27.019 9.24646 24.3228 10.6019 22.3312L20.5199 7.75762Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_10257_29359' x='0.078125' y='0.445312' width='45.8438' height='40.5732' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.172549 0 0 0 0 0.568627 0 0 0 0 0.701961 0 0 0 0.3 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10257_29359'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10257_29359' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  position: absolute;
  bottom: calc(100% - 23px);
  right: 4px;
}

.dots-menu-content {
  position: relative;
  padding: 14px;
  background: #fff;
  border-radius: 10px;
  z-index: 1;
}

@media (max-width: 991px) {
  .dots-menu-inner {
    left: 16px;
    min-width: 180px;
  }
}
</style>
