import axios from 'axios';

const apiUrl = process.env.BASE_URL;

const profileData = {
    methods: {
        async getProfileData() {
            const token = localStorage.getItem('token');

            try {
                const { data } = await axios.get(
                    `${apiUrl}/api/profile`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                return data.status;
            } catch (error) {
                console.log(error);
            }
        },
    },
};

export default profileData;
