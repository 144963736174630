<template>
  <div class="input-wrapper">
    <svg
      class="input-icon"
      width="15"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2143 5.6H9.42857V4C9.42857 1.8 7.66071 0 5.5 0C3.33929 0 1.57143 1.8 1.57143 4V5.6H0.785714C0.353571 5.6 0 5.96 0 6.4V12.8C0 14.56 1.41429 16 3.14286 16H7.85714C9.58571 16 11 14.56 11 12.8V6.4C11 5.96 10.6464 5.6 10.2143 5.6ZM3.14286 4C3.14286 2.68 4.20357 1.6 5.5 1.6C6.79643 1.6 7.85714 2.68 7.85714 4V5.6H3.14286V4ZM7.85714 14.4C8.72143 14.4 9.42857 13.68 9.42857 12.8V7.2H1.57143V12.8C1.57143 13.68 2.27857 14.4 3.14286 14.4H7.85714Z"
        fill="#6F96D7"
      />
    </svg>
    <input
      :type="typeRender"
      class="input"
      :class="className"
      placeholder="Password"
      @input="changeValue($event)"
    />
    <button
      class="form-element-eye"
      @click="toggleSymbols()"
      type="button"
    ></button>
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
    },
  },
  data() {
    return {
      isText: false,
    };
  },
  computed: {
    typeRender() {
      return this.isText ? "text" : "password";
    },
  },
  methods: {
    toggleSymbols() {
      this.isText = !this.isText;
    },
    changeValue(e) {
      this.$emit("change", e.target.value);
    },
  },
};
</script>

<style scoped>
.input-wrapper {
  margin-bottom: 15px;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #d1d8dd;
  border-radius: 35px;
  overflow: hidden;
  z-index: 1;
}

.input-icon {
  margin-left: 31px;
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.input {
  padding: 17px 31px 17px 54px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border: none;
  outline: none;
  color: #3a4146;
}

.input::placeholder {
  color: #3a4146;
}
</style>
