<template>
  <div>
    <label class="permission" for="permission-1">
      <div class="permission-checkbox">
        <input type="checkbox" v-model="permission_1" id="permission-1" />
        <span class="permission-checkbox-style"></span>
      </div>
      <span class="permission-text">
        Allow users to distribute my content with creator attribution. This
        permission can increase your sales.
      </span>
    </label>
    <label class="permission" for="permission-2">
      <div class="permission-checkbox">
        <input type="checkbox" v-model="permission_2" id="permission-2" />
        <span class="permission-checkbox-style"></span>
      </div>
      <span class="permission-text">
        I am the author of this content AND I am aware that I cannot sell other
        people’s creative works.
        <span @click="openGuidelines" class="blue">See Guidelines</span>
      </span>
    </label>
    <v-button
      class="centered"
      :disabled="!permission_1 || !permission_2"
      @click="confirm"
    >
      Confirm & Next
    </v-button>
  </div>
</template>

<script>
import vButton from "../../ui/v-button.vue";

export default {
  components: {
    vButton,
  },

  data() {
    return {
      permission_1: false,
      permission_2: false,
    };
  },

  methods: {
    confirm() {
      this.$emit("confirm");
    },

    openGuidelines() {
      this.$emit("openGuidelines");
    },
  },
};
</script>

<style scoped>
.permission {
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.permission-checkbox {
  position: relative;
  margin-right: 19px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  border: 2px solid #619be4;
  border-radius: 3px;
}

.permission-checkbox input {
  position: absolute;
  visibility: hidden;
}

.permission-checkbox input:checked + .permission-checkbox-style:before {
  content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1L4.4375 9L1 5.36364' stroke='%23619BE4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.permission-text {
  color: #3a4146;
  line-height: 20px;
}

.centered {
  display: block;
  margin: 0 auto;
}

.blue {
  color: #2658d2;
  text-decoration: underline;
  cursor: pointer;
}

::v-deep .button {
  margin-top: 31px;
}
</style>
