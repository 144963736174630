<template>
  <div class="header-container">
    <HeaderComponent
      ref="headerComponent"
      :opacity="headerOpacity"
      :headerType="headerType"
      @goToLogin="goToLogin"
      @goToChangePass="goToChangePass"
      @menuToggle="menuToggle"
      @logout="goToLogout"
      @goToInvite="goToInvite"
      @mobileMenuClose="menuClose"
      :authKey="authKey"
    />
    <MobileMenu
      v-show="menu"
      :status="menu"
      @menuClose="menuClose"
      @goToLogin="goToLogin"
      @goToLogout="goToLogout"
      @goToChangePass="goToChangePass"
      @goToInvite="goToInvite"
    />
    <InviteComponent v-if="toInvite" @inviteClose="inviteClose" />
    <LoginComponent
      v-if="getLogin"
      @checkReward="checkReward"
      @loginClose="loginClose"
      @goToForgotPassword="goToForgotPassword"
      @goToSignUp="goToSignUp"
      @showNotification="showNotification"
    />
    <RewardModal v-if="reward" @modalClose="rewardModalClose" />
    <SignUpComponent
      v-if="toSignUp"
      @signUpClose="loginClose"
      @backToLogin="backToLogin"
      @welcome="signUpComplete"
    />
    <ForgotPasswordComponent
      v-if="toForgotPassword"
      @restoreComplete="restoreComplete"
      @loginClose="loginClose"
    />
    <ChangePasswordComponent
      v-if="toChangePassword"
      @restoreComplete="restoreComplete"
      @loginClose="loginClose"
    />
    <CompleteComponent
      v-if="toComplete"
      :title="title"
      :subTitle="subTitle"
      :buttonText="buttonText"
      @loginClose="completeClose"
    />
    <LogoutComponent v-if="toLogout" @popupClose="logoutClose" />
    <NotificationComponent
      class="is-error is-fixed"
      v-if="notificationText"
      :text="notificationText"
      @closeToast="closeToast()"
    />
  </div>
</template>

<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import HeaderComponent from '../components/headers/headerComponent';
import LoginComponent from '../components/forms/login';
import RewardModal from '../components/modals/rewardModal.vue';
import SignUpComponent from '../components/forms/signUp';
import ForgotPasswordComponent from '../components/forms/restore';
import ChangePasswordComponent from '../components/forms/changePass';
import InviteComponent from '../components/forms/invite';
import CompleteComponent from '../components/forms/complete';
import LogoutComponent from '../components/forms/logout';
import MobileMenu from '../components/mobileMenu';
import NotificationComponent from '../components/toasts/notification';

const apiUrl = process.env.BASE_URL;

export default {
  props: {
    headerType: {
      type: String,
      required: true,
    },
  },

  components: {
    HeaderComponent,
    LoginComponent,
    RewardModal,
    SignUpComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    InviteComponent,
    CompleteComponent,
    LogoutComponent,
    MobileMenu,
    NotificationComponent,
  },

  data() {
    return {
      invited: false,
      reward: false,
      toSignUp: false,
      toForgotPassword: false,
      toComplete: false,
      toLogout: false,
      toChangePassword: false,
      toInvite: false,
      notificationText: '',
      title: '',
      subTitle: '',
      buttonText: '',

      authKey: false,

      menu: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'getLogin']),

    headerOpacity() {
      const isOpacity = Boolean(
        this.getLogin ||
          this.toSignUp ||
          this.toForgotPassword ||
          this.toComplete ||
          this.toChangePassword
      );

      return isOpacity;
    },
  },

  methods: {
    async checkReward() {
      const formData = new FormData();
      const token = localStorage.getItem('token');

      formData.append('reward[name]', 'downloaded new apk');

      const { data } = await axios.post(`${apiUrl}/api/rewards`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (data.reward_status) {
        this.$store.dispatch('getCurrency');
        this.reward = true;
      }
    },

    rewardModalClose() {
      this.reward = false;
    },

    showNotification(text) {
      this.loginClose();
      this.notificationText = text;
    },

    closeToast() {
      this.notificationText = '';
    },

    goToLogin() {
      if (!this.headerOpacity) {
        document.body.style.overflow = 'hidden';
      }

      this.toForgotPassword = false;
      this.toSignUp = false;
      this.$store.dispatch('auth/setLogin', true);
    },

    menuToggle() {
      this.menu = !this.menu;

      if (this.menu && window.innerWidth <= 767)
        document.body.style.overflow = 'hidden';
    },

    menuClose() {
      this.menu = false;
      document.body.removeAttribute('style');
    },

    goToForgotPassword() {
      this.$store.dispatch('auth/setLogin', false);
      this.toForgotPassword = true;
    },

    goToLogout() {
      this.toLogout = true;
    },

    goToInvite() {
      this.toInvite = true;
    },

    goToSignUp() {
      this.$store.dispatch('auth/setLogin', false);
      this.toSignUp = true;
    },

    goToChangePass() {
      if (!this.headerOpacity) {
        document.body.style.overflow = 'hidden';
      }

      this.toChangePassword = true;
    },

    backToLogin() {
      this.toForgotPassword = false;
      this.toSignUp = false;
      this.$store.dispatch('auth/setLogin', true);
    },

    signUpComplete() {
      this.toSignUp = false;
      this.toComplete = true;

      this.title = 'Welcome!';
      this.subTitle = 'Check your email for the verification link.';
      this.buttonText = 'Start Now';
    },

    restoreComplete() {
      this.title = 'Sent!';
      this.subTitle = 'Check your email for the password reset link.';
      this.buttonText = 'Explore Pixchange';

      this.toForgotPassword = false;
      this.toComplete = true;
    },

    logoutClose() {
      this.toLogout = false;

      document.body.removeAttribute('style');
    },

    inviteClose() {
      this.toInvite = false;
    },

    loginClose() {
      this.$store.dispatch('auth/setLogin', false);
      this.toForgotPassword = false;
      this.toSignUp = false;
      this.toComplete = false;
      this.toChangePassword = false;

      this.authKey = !this.authKey;

      document.body.removeAttribute('style');

      if (Object.keys(this.$route.query).length) {
        const path = this.$route.path;
        this.$router.push({ path });
      }
    },

    completeClose() {
      this.loginClose();
      this.checkReward();
    },
  },

  watch: {
    '$route.query'(value) {
      if (Object.keys(value)[0] === 'user-invitation') this.goToSignUp();
    },

    '$route.query.forgot': {
      handler(value) {
        if (value) {
          this.toForgotPassword = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.header-container {
  width: 100%;
}
</style>
