<template>
  <button
    class="add-album-button"
    :class="{ 'modal-item': modalItem, high }"
    @click="onClick"
  >
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M0 29.5C0 27.6897 1.46751 26.2222 3.27778 26.2222H55.7222C57.5325 26.2222 59 27.6897 59 29.5C59 31.3103 57.5325 32.7778 55.7222 32.7778H3.27778C1.46751 32.7778 0 31.3103 0 29.5Z"
          :fill="`url(#paint0_linear_${id})`"
        />
        <path
          d="M29.5 0C31.3103 7.91293e-08 32.7778 1.46751 32.7778 3.27778L32.7778 55.7222C32.7778 57.5325 31.3103 59 29.5 59C27.6897 59 26.2222 57.5325 26.2222 55.7222L26.2222 3.27778C26.2222 1.46751 27.6897 -7.91293e-08 29.5 0Z"
          :fill="`url(#paint0_linear_${id})`"
        />
      </g>
      <defs>
        <linearGradient
          :id="`paint0_linear_${id}`"
          x1="29.5"
          y1="0"
          x2="29.5"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2659D2" />
          <stop offset="1" stop-color="#34A6E7" />
        </linearGradient>
        <linearGradient
          :id="`paint0_linear_${id}`"
          x1="29.5"
          y1="0"
          x2="29.5"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2659D2" />
          <stop offset="1" stop-color="#34A6E7" />
        </linearGradient>
      </defs>
    </svg>
  </button>
</template>

<script>
export default {
  name: "AddAlbumButton",

  props: {
    id: {
      type: String,
      default: "",
    },

    high: {
      type: Boolean,
      default: false,
    },

    modalItem: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.add-album-button {
  padding-top: 75%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 290px;
  border: 1px solid #fff;
  border-radius: 13px;
  cursor: pointer;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.2));
  box-sizing: content-box;
}

@media (max-width: 767px) {
  .add-album-button {
    padding-top: 100%;
  }
}

.add-album-button svg {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-album-button:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(to top, #265bd3, #34a6e7);
}

.add-album-button:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #fff;
  border-radius: inherit;
}

@media (max-width: 1309px) {
  .add-album-button {
    min-width: auto;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .add-album-button {
    border-radius: 6px;
  }
  .add-album-button svg {
    width: 32px;
    height: 32px;
  }
}

/* @media (max-width: 1023px) {
  .add-album-button {
    min-width: 360px;
  }
}

@media (max-width: 788px) {
  .add-album-button {
    min-width: 300px;
  }
}

@media (max-width: 575px) {
  .add-album-button {
    max-width: 290px;
  }
} */

.add-album-button.modal-item {
  min-width: auto;
  width: 100%;
}
</style>
