import axios from "axios";

const apiUrl = process.env.BASE_URL;

const UserService = {
  fetchUser(userId) {
    const token = localStorage.getItem("token");

    const options = token
      ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
      }
      : {
        method: "GET",
      };

    return fetch(`${apiUrl}/api/users/${userId}`, options)
      .then((response) => response.json())
      .catch((err) => {
        throw err;
      });
  },

  updateProfile(payload) {
    const token = localStorage.getItem("token");

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
      body: payload,
    };

    return fetch(`${apiUrl}/api/profile`, options)
      .then((response) => response.json())
      .catch((err) => {
        throw err;
      });
  },

  updateProfileImages(payload) {
    const token = localStorage.getItem("token");

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
      body: payload,
    };
    return fetch(`${apiUrl}/api/profile/images`, options)
      .then((response) => response.json())
      .catch((err) => {
        throw err;
      });
  },

  fetchArtistAlbums(user_id, page = 1) {
    const token = localStorage.getItem("token");

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    };

    return fetch(`${apiUrl}/api/users/${user_id}/albums?page=${page}`, options)
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        throw err;
      });
  },

  fetchUserAlbums(page = 1) {
    const token = localStorage.getItem("token");

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    };

    return fetch(`${apiUrl}/api/albums?page=${page}`, options)
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        return "error";
      });
  },

  fetchUserAlbumImages(payload) {
    return fetch(
      `${apiUrl}/api/users/${payload.userId}/albums/${payload.albumId}/images`
    )
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        return "error";
      });
  },

  fetchPrivateAlbumImages(payload) {
    const token = localStorage.getItem("token");

    return fetch(`${apiUrl}/api/albums/${payload.albumId}/images`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        return "error";
      });
  },

  fetchTopImages(userId) {
    return fetch(`${apiUrl}/api/users/${userId}/images`)
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        throw err;
      });
  },

  async fetchNftCollections(artistId) {
    let getNftCollection;

    if (artistId) {
      getNftCollection = async (page = 1) => {
        try {
          const { data } = await axios.get(
            `${apiUrl}/api/users/${artistId}/collections?page=${page}`
          );

          return data;
        } catch (error) {
          console.log(error);
        }
      };
    } else {
      const token = localStorage.getItem("token");

      getNftCollection = async (page = 1) => {
        try {
          const { data } = await axios.get(
            `${apiUrl}/api/collections?page=${page}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          return data;
        } catch (error) {
          console.log(error);
        }
      };
    }

    const response = await getNftCollection();

    if (response.total_pages > 1) {
      for (let page = 2; page <= response.total_pages; page++) {
        const pageResponse = await getNftCollection(page);

        response.collection = [
          ...response.collection,
          ...pageResponse.collection,
        ];
      }
    }

    if (artistId && typeof response.collection !== "string")
      response.collection = response.collection.filter(
        (item) => item.nft_status === "minted"
      );

    return typeof response.collection === "string" ? [] : response.collection;
  },

  async fetchNftCollected() {
    const token = localStorage.getItem("token");
    const userId = JSON.parse(localStorage.getItem("vuex")).auth.user.id;

    const getNftCollected = async (page = 1) => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/api/albums/collected/${userId}/tokens?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        return data;
      } catch (error) {
        console.log(error);
      }
    };

    const response = await getNftCollected();

    if (response.total_pages > 1) {
      for (let page = 2; page <= response.total_pages; page++) {
        const pageResponse = await getNftCollected(page);

        response.collection = [
          ...response.collection,
          ...pageResponse.collection,
        ];
      }
    }

    return response.collection;
  },
};

export default UserService;
