<template>
    <!-- <div :class="{ 'modal-item': modalItem }"> -->
    <div
        ref="albumItem"
        :class="album.cover && album.cover.image ? 'album-item' : 'album-item default-cover'"
    >
        <!-- <DotsMenu v-if="album.shared"> -->
        <DotsMenu v-if="isPlaceholder" ref="dotsMenu" @menuToggle="menuToggle">
            <DotsItem red title="Delete Album" @click="deleteAlbumPlaceholder" />
        </DotsMenu>
        <DotsMenu v-else-if="allowedActions.length && dots" ref="dotsMenu" @menuToggle="menuToggle">
            <DotsItem
                v-if="allowedActions.includes('share')"
                :title="'Share Link'"
                @click="onShareAlbumClick"
            />
            <DotsItem
                v-if="allowedActions.includes('publish')"
                :title="'Publish Album'"
                @click="onPublishAlbumClick"
            />
            <DotsItem
                v-if="allowedActions.includes('unpublish')"
                :title="'Unpublish Album'"
                @click="onPublishAlbumClick"
            />
            <DotsItem
                v-if="allowedActions.includes('rename')"
                title="Rename Album"
                @click="onRenameAlbumClick"
            />
            <DotsItem
                red
                v-if="allowedActions.includes('delete')"
                title="Delete Album"
                @click="deleteAlbumModalShow"
            />
        </DotsMenu>

        <div @click="onAlbumItemClick" class="album-item-inner">
            <p v-if="!isTitleEditing" class="album-title">
                {{ title }}
            </p>
            <input
                v-else
                ref="albumTitle"
                class="album-title"
                v-model="title"
                @keydown="saveTitleClick"
                v-click-outside="titleInputClickOutside"
            />
            <ImageItem v-if="album.cover" :image="album.cover.image" width="290" height="218" />
            <PreloaderComponent v-if="isLoading" class="preloader-wrapper" :is-absolute="true" />
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import { mapGetters } from 'vuex';

import DotsMenu from '../dotsMenu/DotsMenu.vue';
import DotsItem from '../dotsMenu/DotsItem.vue';
import PreloaderComponent from '../../components/preloader';
import ImageItem from '../../components/imageItem/imageItem.vue';

import profileData from '../mixins/profileData';

import services from '../../../services';

import ClickOutside from 'vue-click-outside';

const apiUrl = process.env.BASE_URL;

export default {
    mixins: [profileData],

    components: {
        DotsMenu,
        DotsItem,
        PreloaderComponent,
        ImageItem,
    },

    directives: {
        ClickOutside,
    },

    props: {
        album: {
            type: Object,
            default: () => {},
        },

        dots: {
            type: Boolean,
            default: false,
        },

        modalItem: {
            type: Boolean,
            default: false,
        },

        isPlaceholder: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            albumdata: null,
            isLoading: false,
            allowedActions: [],
            isTitleEditing: false,
            readyToSave: true,
            title: '',
        };
    },
    computed: {
        ...mapGetters('auth', ['currentUser']),
    },
    methods: {
        menuToggle() {
            document.querySelectorAll('.album-item').forEach((item) => item.classList.remove('z-index-inc'));

            this.$nextTick(() => this.$refs.albumItem.classList.add('z-index-inc'));
        },

        onAlbumItemClick() {
            if (this.isPlaceholder) {
                this.fastEdit();

                return;
            }

            if (!this.isTitleEditing) this.$emit('click', this.album);
        },

        fastEdit() {
            this.readyToSave = false;

            this.onRenameAlbumClick();

            setTimeout(() => {
                this.readyToSave = true;
            }, 0);
        },

        saveTitleClick(event) {
            if (event.key === 'Enter') this.saveTitle();
        },

        async saveTitle() {
            this.isTitleEditing = false;

            if (!this.isPlaceholder) {
                await services.albums.updateAlbum({
                    id: this.album.id,
                    title: this.title,
                });

                this.$emit('change', this.album);

                return;
            }

            this.$emit('change', this.title);
        },

        titleInputClickOutside(event) {
            if (!event.target.classList.contains('dots-item') && this.readyToSave) this.saveTitle();
        },

        async onPublishAlbumClick() {
            try {
                const status = await this.getProfileData();

                if (status === 'not_confirmed') {
                    this.$emit('isNotConfirmed');

                    return;
                }

                this.isLoading = true;

                const method = this.album.published ? 'unpublishAlbum' : 'publishAlbum';

                await services.albums[method](this.album.id);

                this.isLoading = false;

                this.$emit('change', this.album.published);
            } catch (e) {
                console.log(e);
            }
        },

        async onShareAlbumClick() {
            try {
                const status = await this.getProfileData();

                if (status === 'not_confirmed') {
                    this.$emit('isNotConfirmed');

                    return;
                }

                const shareLink = `${apiUrl}/users/${this.currentUser.id}/albums/${this.album.id}?title=${this.album.title}&guest=true`;
                window.open(shareLink);
            } catch (e) {
                console.log(e);
            }
        },

        async onRenameAlbumClick() {
            const status = await this.getProfileData();

            if (status === 'not_confirmed') {
                this.$emit('isNotConfirmed');

                return;
            }

            this.isTitleEditing = true;

            this.$nextTick(() => {
                this.$refs.albumTitle.value = '';
                this.$refs.albumTitle.focus();
            });
        },

        async deleteAlbumModalShow() {
            const status = await this.getProfileData();

            if (status === 'not_confirmed') {
                this.$emit('isNotConfirmed');

                return;
            }

            this.$emit('deleteAlbumModalShow', this.album);
        },

        deleteAlbumPlaceholder() {
            this.$emit('deleteAlbumPlaceholder');
        },
    },

    mounted() {
        if (this.isPlaceholder) this.fastEdit();
    },

    watch: {
        album: {
            handler(newVal) {
                this.allowedActions = [];
                this.title = newVal.title;

                if (!newVal.default) this.allowedActions.push('rename', 'delete');

                if (newVal.can_publish && !newVal.default && !newVal.published)
                    this.allowedActions.push('publish');

                if (newVal.published) {
                    this.allowedActions.push('share');
                    this.allowedActions.push('unpublish');
                }
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style scoped>
.album-item {
    padding-top: 75%;
    position: relative;
    display: block;
    background: #fff;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.2));
    will-change: filter;
}

@media (max-width: 767px) {
    .album-item {
        padding-top: 100%;
    }
}

.album-item-inner {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 13px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
}

.z-index-inc {
    z-index: 2;
}

@media (max-width: 1309px) {
    .album-item {
        min-width: auto;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .album-item {
        border-radius: 6px;
    }
    .album-item-inner {
        border-radius: 6px;
    }
}

.default-cover {
    background: linear-gradient(313.17deg, #34a6e7 5.54%, #1c23c3 161.74%);
}

.default-cover .album-item-inner:after {
    content: url('../../../../public/img/icons/blue-cross.png');
    position: absolute;
    opacity: 0.2;
    pointer-events: none;
}

@media (max-width: 991px) {
    .default-cover .album-item-inner:after {
        transform: scale(0.5);
    }
}

.album-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.album-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 43px;
    background: rgba(240, 240, 240, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.26px;
    text-transform: uppercase;
    text-align: center;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border: none;
    outline: none;
    z-index: 1;
}

p.album-title {
    pointer-events: none;
}

@media (max-width: 991px) {
    .album-title {
        font-size: 13px;
        font-weight: 400;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.album-title-input {
    position: absolute;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 20px;
    border: none;
    outline: none;
    width: 270px;
    text-transform: uppercase;
    transition: height 1s;
    -webkit-transition: height 1s;
    background-color: rgba(0, 0, 0, 0);
}

@media (max-width: 575px) {
    .album-item {
        max-width: 290px;
    }
}

.modal-item .album-item {
    min-width: auto;
    width: 100%;
}

.preloader-wrapper {
    border-radius: 13px;
    overflow: hidden;
}

@media (max-width: 991px) {
    .preloader-wrapper {
        border-radius: 6px;
    }
}
</style>
