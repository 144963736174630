<template>
  <div class="nav-tabs">
    <ul
      v-show="!hidden"
      class="nav-list"
      :class="{ 'bottom-indent': tabTitles.length }"
    >
      <li
        v-for="(title, index) in tabTitles"
        :key="index"
        :id="`tab-${index}`"
        @click="changeSelectedTitle(title, index)"
        :class="subTabs ? 'sub-nav-item' : 'nav-item'"
      >
        <span :class="{ active: selectedTitle === title }" class="nav-link">
          {{ title }}
        </span>
      </li>
    </ul>
    <slot name="hint" />
    <slot name="top" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    subTabs: {
      type: Boolean,
      default: false,
    },

    queryTab: {
      type: Boolean,
      default: false,
    },

    defaultTitle: {
      type: Number,
      default: 0,
    },

    hidden: {
      type: Boolean,
      default: false,
    },

    activeImageTab: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      componentOptions: [],
      tabTitles: [],
      activeTitle: "",
    };
  },

  provide() {
    return {
      providedTitle: () => this.selectedTitle,
    };
  },

  computed: {
    selectedTitle() {
      return this.activeTitle;
    },
  },

  methods: {
    getTabTitles() {
      if (this.$slots.default) {
        this.componentOptions = this.$slots.default.filter(
          (item) => item.componentOptions
        );
      }

      this.tabTitles = this.componentOptions.map(
        (item) => item.componentOptions.propsData.title
      );

      if (this.queryTab) {
        let defaultTitle;

        if (!this.subTabs) {
          defaultTitle = this.componentOptions.findIndex(
            (item) =>
              item.componentOptions.propsData.query === this.$route.query.tab
          );
        } else {
          defaultTitle = this.componentOptions.findIndex(
            (item) =>
              item.componentOptions.propsData.query === this.$route.query.type
          );
        }

        if (defaultTitle !== -1) {
          this.activeTitle = this.tabTitles[defaultTitle];

          return;
        }
      }

      this.activeTitle = this.tabTitles[this.defaultTitle];
    },

    changeSelectedTitle(title, index) {
      if (this.activeImageTab) sessionStorage.setItem("activeImagesTab", index);

      this.activeTitle = title;

      if (this.queryTab) {
        const queryValue = this.componentOptions[index].componentOptions
          .propsData.query;

        if (!this.subTabs) {
          if (queryValue !== this.$route.query.tab)
            this.$router.push({
              name: this.$route.name,
              query: {
                tab: queryValue,
              },
            });

          return;
        }

        if (queryValue !== this.$route.query.type)
          this.$router.push({
            name: this.$route.name,
            query: {
              tab: this.$route.query.tab,
              type: queryValue,
            },
          });

        return;
      }

      this.$emit("changeTab");
    },
  },

  mounted() {
    this.getTabTitles();

    if (this.activeImageTab) sessionStorage.setItem("activeImagesTab", 0);
  },
};
</script>

<style scoped>
.nav-list {
  display: flex;
  justify-content: center;
}

.bottom-indent {
  margin-bottom: 37px;
}

.nav-item {
  padding: 0 18px;
  text-align: center;
  list-style-type: none;
}

.sub-nav-item {
  list-style-type: none;
}

.sub-nav-item:first-child {
  margin-right: -20px;
}

.sub-nav-item:last-child {
  margin-left: -20px;
}

@media (max-width: 767px) {
  .nav-item {
    padding: 0 17px;
  }

  .nav-item:first-child {
    padding-left: 0;
  }

  .nav-item:last-child {
    padding-right: 0;
  }
}

.nav-item .nav-link {
  color: #3a4146;
  font-family: SF Pro Display;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
}

.nav-item .nav-link.active {
  position: relative;
  color: #2969d6;
}

.nav-item .nav-link.active:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  display: block;
  width: 100%;
  height: 3px;
  background: #2969d6;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  pointer-events: none;
}

.sub-nav-item .nav-link {
  position: relative;
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  background: #fafafa;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.25));
  cursor: pointer;
}

.sub-nav-item .nav-link.active {
  color: #fff;
  background: linear-gradient(264.92deg, #1c23c3 10.3%, #39c0ee 110.14%);
  box-shadow: 0px 4px 10px rgba(49, 146, 226, 0.3);
  z-index: 1;
}

@media (max-width: 767px) {
  .nav-item .nav-link.active:after {
    height: 3px;
    bottom: -8px;
    box-shadow: none;
  }
}
</style>
