import axios from "axios";

const apiUrl = process.env.BASE_URL;

export async function saveImage(imageObj) {
  const usertoken = localStorage.getItem("token");

  if (imageObj.error) return;

  const formData = new FormData();

  formData.append("attachment[content_type]", imageObj.type);
  formData.append("attachment[width]", imageObj.width);
  formData.append("attachment[height]", imageObj.height);

  try {
    const { data } = await axios.post(`${apiUrl}/api/attachments`, formData, {
      headers: {
        Authorization: `Bearer ${usertoken}`,
      },
    });

    imageObj.id = data.id;

    const fd = new FormData();

    for (var key in data.fields) {
      fd.append(`${key}`, data.fields[key]);
    }

    fd.append("file", imageObj);

    await axios.post(data.url, fd);
  } catch (error) {
    console.log(error);
  }
}

export async function sendImageData(data) {
  const token = localStorage.getItem("token");

  const images = data.images;

  const imagesObj = images.map((elem) => {
    const obj = {
      attachment_id: `${elem.id}`,
      title: elem.name,
      allow_publish: elem.allow_publish || true,
      album_id: `${data.album.id}`,
      price: 0,
      for_sale: elem.for_sale || false,
      tags: elem.tags ? [...elem.tags] : data.tags ? [...data.tags] : [],
    };

    if (data.category) obj.subcategory_id = `${data.category.id}`;

    return obj;
  });

  const imagesJson = JSON.stringify({ image_batch: imagesObj });

  try {
    await axios.post(`${apiUrl}/api/web/images/batches`, imagesJson, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    console.log(error);
  }
}

export async function sendNftData(data) {
  const token = localStorage.getItem("token");

  const images = data.images;

  for (let index = 0; index < images.length; index++) {
    const item = images[index];

    const formData = new FormData();

    const newName = item.name
      .split(".")
      .slice(0, -1)
      .join(".");

    formData.append("image[title]", newName);
    formData.append("image[price]", 0);
    formData.append("image[album_id]", data.album.id);
    formData.append("image[attachment_id]", item.id);
    formData.append("image[for_sale]", false);
    formData.append("image[public]", false);
    formData.append("image[allow_publish]", true);

    if (item.tags)
      item.tags.forEach((tag) => {
        formData.append("image[tags][]", tag);
      });

    try {
      await axios.post(`${apiUrl}/api/tokens`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}
