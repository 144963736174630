<template>
  <div class="login-fade">
    <p class="login-title">{{ title }}</p>
    <p class="login-subtitle">
      {{ subTitle }}
    </p>
    <div class="login">
      <div class="circle">
        <svg
          width="45"
          height="32"
          viewBox="0 0 45 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.4768 1.12891L14.3472 30.4622L1.10645 17.1289"
            stroke="#1AB26B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <button class="login-submit outer" @click="loginClose">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },

    subTitle: {
      type: String,
      default: "",
    },

    buttonText: {
      type: String,
      default: "",
    },
  },

  methods: {
    loginClose() {
      if (this.$route.name !== "profile") {
        this.$router.push("/profile");
      }
      this.$emit("loginClose");
    },
  },
};
</script>

<style scoped>
.login-title {
  margin-bottom: 56px;
  color: #1e2541;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

@media (max-width: 767px) {
  .login-title {
    font-size: 24px;
    line-height: 1;
  }
}

.login-subtitle {
  margin: -27px 0 45px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1e2541;
  text-align: center;
}

.login-fade {
  padding: 50px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(242, 242, 242, 0.85);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 991px) {
  .login-fade {
    padding-top: 100px;
  }
}

.login {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: 0 20px;
  padding: 58px 70px 52px;
  max-width: 456px;
  height: fit-content;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
}

@media (max-width: 991px) {
  .login {
    padding: 58px 20px 52px;
  }
}

.login-submit {
  display: block;
  margin: 36px auto 0;
  width: 100%;
  height: 50px;
  color: #fff;
  background: linear-gradient(315deg, #1f32c7 0%, #3cd1f3 100%);
  border-radius: 23px;
  border: none;
  font-family: SF Pro Display;
  font-style: normal;
  font-size: 16px;
  cursor: pointer;
}

.login-submit.outer {
  padding: 15px 55px;
  width: auto;
}

.login-submit:disabled {
  opacity: 0.3;
  cursor: initial;
}

.circle {
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
}
</style>
