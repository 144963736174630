<template>
    <section>
        <div class="blue-section">
            <div class="back-btn-container"></div>
            <div class="container" :style="coverImageStyle">
                <div class="blue-section-inner">
                    <!-- <div class="share-wrapper">
            <div
              v-show="shareLink"
              class="share-link"
              v-click-outside="shareLinkClose"
            >
              <div class="share-link-inner">
                <p class="share-title">Profile Link Copied</p>
                <input
                  ref="inviteInput"
                  type="text"
                  class="share-input"
                  :value="inviteLink"
                  readonly
                />
              </div>
            </div>
            <button @click="openShareLink" class="share-profile">
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 7C17.6569 7 19 5.65685 19 4C19 2.34315 17.6569 1 16 1C14.3431 1 13 2.34315 13 4C13 5.65685 14.3431 7 16 7Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 14C5.65685 14 7 12.6569 7 11C7 9.34315 5.65685 8 4 8C2.34315 8 1 9.34315 1 11C1 12.6569 2.34315 14 4 14Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 21C17.6569 21 19 19.6569 19 18C19 16.3431 17.6569 15 16 15C14.3431 15 13 16.3431 13 18C13 19.6569 14.3431 21 16 21Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.58984 12.5078L13.4198 16.4878"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.4098 5.50781L6.58984 9.48781"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div> -->
                    <UploadButton v-if="uploadShow" @click="addModalOpen" />
                    <div class="square">
                        <img
                            v-if="user.profile_image && user.profile_image.url"
                            :src="user.profile_image.url"
                            width="317"
                            height="317"
                            loading="lazy"
                            :alt="`${user.username} profile image`"
                            class="square-img"
                        />
                        <span v-else class="image-placeholder"></span>
                    </div>
                    <a
                        v-if="$route.name === 'ArtistsAlbumDetails' || $route.name === 'AlbumsPage'"
                        @click="routerBack"
                        class="back-btn"
                    >
                        <span>Back to all albums</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="user-info">
                <p class="user-name">{{ user.full_name }}</p>
                <p class="user-nickname">@{{ user.username }}</p>
                <div id="user-info" class="followers-panel">
                    <!-- <div class="follow-button"><div class="follow-button-text">Follow</div></div> -->
                    <div v-if="user.show_follow_info" class="followers-stats">
                        <div class="follower-info">
                            <span class="counter">{{ user.followers_count }}</span> Followers
                        </div>
                        <div class="follower-info">
                            <span class="counter">{{ user.followings_count }}</span> Following
                        </div>
                    </div>
                    <button
                        v-if="profile || (user && currentUser.id === user.id)"
                        @click="toEditProfilePage"
                        class="edit-profile-button"
                    >
                        Edit Profile
                    </button>
                    <button
                        v-else-if="isAuthenticated && currentUser.id != $route.query.user_id"
                        @click="followToggle"
                        class="edit-profile-button"
                    >
                        <template v-if="!user.followed"> Follow </template>
                        <template v-else> Unfollow </template>
                    </button>
                </div>
                <div :class="{ empty: !user.description }" class="description">
                    <textarea-autosize
                        class="description-text"
                        v-model="user.description"
                        readonly
                    ></textarea-autosize>
                </div>
            </div>
        </div>
        <a
            v-if="$route.name === 'ArtistsAlbumDetails' || $route.name === 'AlbumsPage'"
            @click="routerBack"
            class="back-btn mobile"
        >
            <span>Albums</span>
        </a>
        <ConfirmUserModal v-if="confirmModal" @modalClose="confirmModalClose" />
        <AddModal v-if="addModal" loadData @addModalClose="addModalClose" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import profileData from '../mixins/profileData';

import ClickOutside from 'vue-click-outside';

import ConfirmUserModal from '../modals/confirmUserModal.vue';
import AddModal from '../modals/addModal.vue';
import UploadButton from '../upload/uploadButton.vue';

import { deepLinkTransformMain } from '../../services/shortDeepLink';

const apiUrl = process.env.BASE_URL;

export default {
    name: 'UserInfo',

    mixins: [profileData],

    directives: {
        ClickOutside,
    },

    components: {
        ConfirmUserModal,
        AddModal,
        UploadButton,
    },

    props: {
        user: {
            type: Object,
            required: true,
        },

        profile: {
            type: Boolean,
            default: false,
        },

        publicAlbums: {
            type: Array,
            default: () => [],
        },

        privateAlbums: {
            type: Array,
            default: () => [],
        },

        nftCollections: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            confirmModal: false,
            addModal: false,
            shareLink: false,
            readyToClose: false,
            inviteLink: null,
        };
    },

    computed: {
        ...mapGetters('auth', ['currentUser', 'isAuthenticated']),

        coverImageStyle() {
            return this.user.cover_image
                ? `background-image: url(${this.user.cover_image.url});background-size: cover; background-position: center; background-repeat: no-repeat;`
                : '';
        },

        uploadShow() {
            return this.isAuthenticated ? this.currentUser.id === this.user.id : false;
        },
    },

    methods: {
        routerBack() {
            if (this.user.id === this.currentUser.id) {
                if (this.$route.name === 'ArtistsAlbumDetails') {
                    this.$router.push({
                        name: 'Profile',
                        query: {
                            tab: 'albums',
                            type: 'public',
                        },
                    });

                    return;
                }

                this.$router.push({
                    name: 'Profile',
                    query: {
                        tab: 'albums',
                        type: 'private',
                    },
                });

                return;
            }

            this.$router.push({
                name: 'ArtistPage',
                params: {
                    id: this.user.id,
                },
                query: {
                    tab: 'albums',
                },
            });
        },

        async followToggle() {
            this.user.followed
                ? await this.$store.dispatch('userUnfollow', this.user.id)
                : await this.$store.dispatch('userFollow', this.user.id);

            this.$emit('userDataUpdate');
        },

        confirmModalClose() {
            this.confirmModal = false;
        },

        async addModalOpen() {
            const status = await this.getProfileData();

            if (status === 'not_confirmed') {
                this.confirmModal = true;

                return;
            }

            this.addModal = true;
        },

        addModalClose() {
            this.addModal = false;
        },

        async toEditProfilePage() {
            const status = await this.getProfileData();

            if (status === 'not_confirmed') {
                this.confirmModal = true;
            } else {
                this.$router.push({ name: 'EditProfile' });
            }
        },

        openShareLink() {
            this.shareLink = true;

            this.$nextTick(() => this.copyShareLink());

            setTimeout(() => {
                this.readyToClose = true;
            }, 0);
        },

        copyShareLink() {
            this.$refs.inviteInput.select();
            document.execCommand('copy');
        },

        shareLinkClose(event) {
            if (this.readyToClose && !event.target.classList.contains('share-input')) {
                this.shareLink = false;
                this.readyToClose = false;
            }
        },

        async invitationLinkCreate(user) {
            const { shortLink } = await deepLinkTransformMain(`${apiUrl}/users/${user.id}`);

            this.inviteLink = shortLink;
        },
    },

    watch: {
        user: {
            handler(value) {
                this.invitationLinkCreate(value);
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>

<style lang="css" scoped>
.container {
    position: relative;
    margin: 0 auto;
    padding: 0 30px;
    max-width: 1310px;
    height: 100%;
    box-sizing: border-box;
}

.back-btn-container {
    position: relative;
    margin: 0 auto;
    padding: 0 30px;
    max-width: 1310px;
    height: 100%;
    box-sizing: border-box;
}

@media (max-width: 991px) {
    .container {
        padding: 0 38px;
    }
}

@media (max-width: 767px) {
    .container {
        padding: 0 19px;
    }
}

/* Blue Section */

.blue-section {
    position: relative;
    margin-bottom: 33px;
    z-index: 1;
}

.blue-section .container {
    padding: 0;
    max-width: 1428px;
    filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
    background: linear-gradient(264.92deg, #1c23c3 10.3%, #39c0ee 110.14%);
}

.blue-section .container:before {
    content: '';
    display: block;
    padding-top: 28%;
}

@media (max-width: 767px) {
    .blue-section .container:before {
        padding-top: 40%;
    }
}

.blue-section-inner {
    display: flex;
    justify-content: flex-end;
    padding: 25px 30px 0;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1310px;
    width: 100%;
    height: 100%;
    z-index: 2;
}

@media (max-width: 767px) {
    .blue-section {
        margin-bottom: 90px;
    }

    .blue-section-inner {
        padding: 12px 23px 0;
    }
}

.square-wrapper {
    position: relative;
    height: 100%;
}

.square {
    position: absolute;
    right: 48px;
    top: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 225px;
    background: #fff;
    border-radius: 8px;
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
    z-index: 1;
}

.square-img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

@media (max-width: 991px) {
    .square {
        width: 163px;
        height: 163px;
    }
}

@media (max-width: 767px) {
    .square {
        right: 23px;
        width: 130px;
        height: 130px;
    }
}

@media (max-width: 374px) {
    .square {
        width: 88px;
        height: 88px;
    }
}

/* User Info */

.user-info {
    max-width: calc(100% - 347px);
}

@media (max-width: 991px) {
    .user-info {
        max-width: none;
    }
}

@media (max-width: 575px) {
    .user-info {
        max-width: none;
    }
}

.user-name {
    color: #1e2541;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
}

.user-nickname {
    color: #6d7d89;
    font-family: SF Pro Display;
    font-style: normal;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.4px;
}

.followers-panel {
    margin: 18px 0 40px;
    display: flex;
    align-items: center;
}

.followers-stats {
    margin: 1px 21px 0 0;
    padding: 1px 3px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    background: #e4eaef;
    border-radius: 23px;
}

@media (max-width: 767px) {
    .followers-panel {
        margin-bottom: 22px;
        position: relative;
    }
}

@media (max-width: 575px) {
    .followers-panel {
        flex-direction: column;
        align-items: flex-start;
    }

    .followers-stats {
        margin-bottom: 20px;
    }
}

.follower-info {
    padding: 0 17px;
    color: #6d7d89;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
}

.counter {
    color: #1e2541;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.edit-profile-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    height: 34px;
    color: #fff;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background: linear-gradient(315deg, #1f32c7 0%, #3cd1f3 100%);
    border: none;
    border-radius: 23px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
}

.description {
    margin-bottom: 50px;
    max-width: 665px;
    min-height: 56px;
}

@media (max-width: 991px) {
    .description {
        margin: 37px 0;
        max-width: none;
    }

    .description.empty {
        min-height: 0;
    }
}

.description-title {
    margin-bottom: 10px;
    color: #1e2541;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
}

.description-text {
    width: 100%;
    height: 22px;
    color: #3a4146;
    font-family: SF Pro Display;
    font-size: 16px;
    letter-spacing: 0.02px;
    line-height: 22px;
    resize: none;
    border: none;
}

@media (max-width: 991px) {
    .description-text {
        padding: 0 1px;
    }
}

.follow-button {
    width: 100px;
    height: 34px;
    left: 90px;
    top: 510px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: linear-gradient(315deg, #1f32c7 0%, #3cd1f3 100%);
    border-radius: 23px;
    margin-bottom: 43px;
}

.follow-button-text {
    left: calc(50% - 40px / 2 - 0.5px);
    /*top: calc(50% - 17px / 2 - 0.5px);*/
    height: 34px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
}

.preview + .image-placeholder {
    display: none;
}

.image-placeholder:after {
    content: url('../../../../public/img/icons/light-cross.svg');
}

@media (max-width: 767px) {
    .image-placeholder {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
    }

    .image-placeholder:after {
        position: absolute;
        top: 3px;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(0.5);
    }
}

.share-wrapper {
    margin: 14px 26px 0 0;
    position: relative;
}

@media (max-width: 767px) {
    .share-wrapper {
        margin-top: 3px;
    }
}

.share-profile {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.share-link {
    position: absolute;
    top: 22px;
    right: -70px;
    width: 292px;
    z-index: 3;
}

.share-link.copied {
    width: max-content;
    right: 26px;
}

.share-link-inner {
    padding: 20px 22px 24px;
    position: relative;
    width: 292px;
    height: 116px;
}

.share-link.copied .share-link-inner {
    width: auto;
    height: auto;
}

.share-link-inner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='312' height='133' viewBox='0 0 312 133' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d_7769_33559)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M20 6C14.4772 6 10 10.4772 10 16V92C10 97.5229 14.4771 102 20 102H32.6591L42.4841 117.127C43.667 118.948 46.333 118.948 47.5159 117.127L57.3409 102H292C297.523 102 302 97.5228 302 92V16C302 10.4772 297.523 6 292 6H20Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_7769_33559' x='0' y='0' width='312' height='132.492' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='4'/%3E%3CfeGaussianBlur stdDeviation='5'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0.172549 0 0 0 0 0.568627 0 0 0 0 0.701961 0 0 0 0.3 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_7769_33559'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_7769_33559' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
}

.share-link.copied .share-link-inner {
    padding: 20px 22px;
}

.share-title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: -0.256px;
    position: relative;
    z-index: 1;
}

.share-link.copied .share-title {
    line-height: 26px;
}

.share-input {
    padding: 7px 12px;
    width: 100%;
    height: 29px;
    border: 1px solid #6d7d89;
    border-radius: 2px;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: -0.256px;
    color: #6d7d89;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.back-btn {
    position: absolute;
    right: 48px;
    bottom: -168px;
    color: #3a4146;
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.back-btn span {
    margin-left: 5px;
    border-bottom: 1px solid #3a4146;
}

.back-btn:before {
    content: '<';
}

@media (max-width: 991px) {
    .back-btn {
        bottom: -125px;
    }
}

@media (max-width: 767px) {
    .back-btn {
        right: 25px;
    }
}

@media (min-width: 426px) {
    .back-btn.mobile {
        display: none;
    }
}

@media (max-width: 425px) {
    .back-btn:not(.mobile) {
        display: none;
    }

    .back-btn.mobile {
        position: static;
        display: inline-block;
        margin: 0 0 10px 15px;
    }
}
</style>
