<template>
  <div :class="type" class="alert">
    <svg
      v-if="type === 'success'"
      class="alert-icon"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8405_39965)">
        <circle cx="19" cy="15" r="15" fill="#191A1C" />
      </g>
      <g filter="url(#filter1_d_8405_39965)">
        <path d="M24.5556 11L16.6111 19L13 15.3636" fill="black" />
        <path
          d="M24.5556 11L16.6111 19L13 15.3636"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8405_39965"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8405_39965"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8405_39965"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8405_39965"
          x="8"
          y="10"
          width="21.5547"
          height="18"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8405_39965"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8405_39965"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <svg
      v-else
      class="alert-icon"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8405_40004)">
        <circle cx="19" cy="15" r="15" fill="#191A1C" />
      </g>
      <path
        d="M19 8L19 16.2979"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle cx="19" cy="21.0625" r="2" fill="white" />
      <defs>
        <filter
          id="filter0_d_8405_40004"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8405_40004"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8405_40004"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
    <span class="alert-text">{{ text }}</span>
    <button @click="alertClose" class="alert-close">
      <svg
        class="close-icon"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_8405_39980)">
          <path
            d="M5 1L14.9998 10.9998"
            stroke="#191A1C"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M15 1L5.00017 10.9998"
            stroke="#191A1C"
            stroke-width="2"
            stroke-linecap="round"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_8405_39980"
            x="0"
            y="0"
            width="20"
            height="20"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8405_39980"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8405_39980"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "success",
    },

    text: {
      type: String,
      required: true,
    },
  },

  methods: {
    alertClose() {
      this.$emit("alertClose");
    },
  },

  mounted() {
    setTimeout(() => {
      this.alertClose();
    }, 5000);
  },
};
</script>

<style scoped>
.alert {
  padding: 14px 20px;
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  max-width: 509px;
  width: 100%;
  background: #dcfdee;
  box-shadow: 0px 8px 15px rgba(26, 10, 10, 0.10043);
  border-radius: 15px;
  z-index: 999999;
}

.alert.info {
  background: #e5f3fe;
}

.alert.warning {
  background: #fff2e1;
}

.alert.error {
  background: #ffe8f0;
}

.alert-icon {
  position: relative;
  top: 4px;
}

.alert-text {
  margin-left: 12px;
  padding-right: 15px;
  flex-grow: 1;
  font-size: 18px;
  line-height: 21px;
}

.alert-close {
  margin-left: 14px;
  padding: 0;
  border: 0;
  background: none;
  width: 19px;
  height: 19px;
  cursor: pointer;
}

.close-icon {
  position: relative;
  top: 4px;
}
</style>
