<template>
  <div class="toast" ref="toast" :class="className">
    <div class="toast__text" v-html="text"></div>
    <button class="toast__close" @click="close">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66675 1.66669L12.8282 12.8281M12.8282 1.66669L1.66678 12.8281"
          stroke="#3A405B"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: ["text", "className"],

  methods: {
    close() {
      this.$emit("close");
    },
  },

  mounted() {
    this.$refs.toast.classList.add("show");
    setTimeout(() => {
      this.$refs.toast.classList.remove("show");
      setTimeout(() => {
        this.close();
      }, 1000);
    }, 5000);
  },
};
</script>

<style scoped>
.toast {
  padding: 14px 46px 14px 18px;
  position: fixed;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  border-radius: 8px;
  background-color: #fff;
  opacity: 0;
  transition: 1s ease;
}
.toast.show {
  opacity: 1;
}
.toast.is-error {
  border: solid 1px #c03536;
  color: #c03536;
}
.toast.is-fixed {
  position: fixed;
  top: 40px;
  right: 40px;
  z-index: 999;
}
.toast__text {
  font-family: "Source Sans Pro";
  font-size: 16px;
  line-height: 20px;
  padding-right: 15px;
}
.toast__close {
  position: absolute;
  top: 17px;
  right: 24px;
  border: none;
  background: none;
  font-size: 0;
  cursor: pointer;
}
</style>
