<template>
  <div class="modal-fade">
    <div :style="`max-width:${width}px`" class="modal">
      <button v-if="!hideCloseButton" @click="modalClose" class="modal-close">
        <svg
          width="18"
          height="18"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.00342 2L20.6685 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
          <path
            d="M20.665 2L1.99993 20.6651"
            stroke="black"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </button>
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 428,
    },

    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    modalClose() {
      document.body.removeAttribute('style');
      this.$emit('modalClose');
    },
  },

  mounted() {
    document.body.style.overflow = 'hidden';
  },

  destroyed() {
    document.body.removeAttribute('style');
  },
};
</script>

<style scoped>
.modal-fade {
  padding: 50px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;

  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin: 20px;
  padding: 30px 20px;
  height: fit-content;
  width: 100%;
  background: #fff;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
}

.modal-close {
  margin-left: auto;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.modal-content {
  position: relative;
  padding: 15px;
  width: 100%;
  height: 100%;
}
</style>
