<template>
  <div class="page-content colored">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.removeAttribute("style");
  },
};
</script>

<style scoped>
.page-content {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1199px) {
  .page-content {
    padding-top: 120px;
  }
}

.colored {
  position: relative;
}

::v-deep p + .button {
  margin-top: auto;
}

::v-deep .button {
  margin: 40px auto 0;
  max-width: 321px;
  width: 100%;
}

::v-deep .button + .button {
  margin-top: 20px;
}

@media (min-width: 992px) {
  .colored:before,
  .colored:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .colored:before {
    background: url("../../../public/img/metamask/background-1.svg");
    opacity: 1;
    animation: 3s fadeIn linear infinite;
  }

  .colored:after {
    background: url("../../../public/img/metamask/background-2.svg");
    opacity: 0;
    animation: 3s fadeOut linear infinite;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
