global.jQuery = require("jquery");

export default function accordion() {
  const $ = global.jQuery;
  $(document).on("click", ".js-accordion-title", function() {
    const parent = $(this).parents(".js-accordion");
    const content = parent.find(".js-accordion-content");
    parent.toggleClass("is-open");
    content.slideToggle();
  });
}
