<template>
  <div :class="{ mobile: isMobile }" class="page-content colored">
    <slot />
  </div>
</template>

<script>
import { mobileDetect } from "../functions/mobileDetect";

export default {
  data() {
    return {
      isMobile: mobileDetect(),
    };
  },

  mounted() {
    document.body.removeAttribute("style");
  },
};
</script>

<style scoped>
.page-content {
  padding-top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.colored {
  position: relative;
}

::v-deep .metamask {
  padding: 50px 30px;
  position: relative;
  margin: 0 auto;
  max-width: 1310px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

@media (max-width: 991px) {
  ::v-deep .metamask {
    padding-top: 150px;
  }
}

::v-deep p + .button {
  margin-top: auto;
}

::v-deep .button {
  margin: 40px auto 0;
  max-width: 321px;
  width: 100%;
}

::v-deep .button + .button {
  margin-top: 20px;
}

.mobile::v-deep .metamask {
  padding-top: 15px;
}

@media (min-width: 1200px) {
  .colored:before,
  .colored:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .colored:before {
    background: url("../../../public/img/metamask/background-1.svg");
    opacity: 1;
    animation: 3s fadeIn linear infinite;
  }

  .colored:after {
    background: url("../../../public/img/metamask/background-2.svg");
    opacity: 0;
    animation: 3s fadeOut linear infinite;
  }

  ::v-deep .metamask {
    padding: 50px 30px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 4px 10px rgba(44, 145, 179, 0.3);
    border-radius: 10px;
  }
}

::v-deep .metamask {
  max-width: 681px;
}

/* @media (max-width: 1439px) {
  ::v-deep .metamask {
    max-width: 400px;
  }
} */

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
