<template>
  <Modal :width="774" @modalClose="modalClose">
    <p class="modal-title">
      Please confirm your account to unlock all the features.
    </p>
    <svg
      class="modal-icon"
      width="62"
      height="81"
      viewBox="0 0 62 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.4607 28.4489H15.3081V20.7898C15.3081 11.0433 22.0547 3.69313 31.0002 3.69313C40.0931 3.69313 46.6923 10.6104 46.6923 20.1412C46.6923 21.1611 47.5184 21.9872 48.5383 21.9872C49.5581 21.9872 50.3843 21.1611 50.3843 20.1412C50.3843 8.65878 42.0504 0 30.9997 0C19.9493 0.000643845 11.6151 8.939 11.6151 20.789V28.448H5.53878C2.48555 28.448 0 30.9327 0 33.9868V74.9779C0 78.0312 2.48472 80.5167 5.53878 80.5167H56.4612C59.5145 80.5167 62 78.032 62 74.9779V33.9852C62 30.9313 59.5159 28.4464 56.4612 28.4464L56.4607 28.4489ZM58.3067 74.9801C58.3067 75.9994 57.4793 76.8261 56.4607 76.8261H5.53829C4.51837 76.8261 3.6923 75.9987 3.6923 74.9801V33.989C3.6923 32.969 4.5184 32.143 5.53829 32.143H56.4607C57.48 32.143 58.3067 32.9691 58.3067 33.989V74.9801Z"
        fill="black"
      />
      <path
        d="M40.2928 50.9967C40.2928 45.8726 36.123 41.7041 31.0003 41.7041C25.8755 41.7041 21.707 45.8739 21.707 50.9967C21.707 55.5458 24.994 59.335 29.3171 60.1295V68.7177C29.3171 69.737 30.1445 70.5637 31.1631 70.5637C32.1824 70.5637 33.0091 69.7363 33.0091 68.7177V60.0659C37.1692 59.1445 40.2928 55.4312 40.2928 50.9967ZM30.9996 56.5978C27.9109 56.5978 25.3985 54.084 25.3985 50.9967C25.3985 47.9093 27.9122 45.3961 30.9996 45.3961C34.087 45.3961 36.6002 47.9085 36.6002 50.9967C36.6002 54.084 34.0884 56.5978 30.9996 56.5978Z"
        fill="black"
      />
    </svg>

    <div class="modal-actions">
      <button class="action-button blue" @click="resendEmail">
        Resend Confirmation Email
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from "./modal.vue";

const apiUrl = process.env.BASE_URL;

export default {
  components: {
    Modal,
  },

  methods: {
    modalClose() {
      document.body.removeAttribute("style");
      this.$emit("modalClose");
    },

    async resendEmail() {
      const token = localStorage.getItem("token");

      fetch(`${apiUrl}/api/auth/confirmations`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => {
          return resp;
        })
        .then(async (data) => {
          const { errors } = data;
          if (errors) {
            console.log(errors);
          } else {
            this.$root.$children[0].$children[0].signUpComplete();
            this.$emit("modalClose");
          }
        });
    },
  },

  mounted() {
    document.body.style.overflow = "hidden";
  },
};
</script>

<style scoped>
.modal-title {
  margin: 0 auto 50px;
  max-width: 485px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #1e2541;
  text-align: center;
}

@media (max-width: 767px) {
  .modal-title {
    font-size: 24px;
    line-height: 1;
  }
}

.modal-icon {
  display: block;
  margin: 0 auto 60px;
}

.modal-text {
  margin-bottom: 30px;
  max-width: 316px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.256px;
  color: #3a4146;
}

.modal-actions {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-button {
  margin: 0 30px;
  width: 318px;
  height: 50px;

  line-height: 20px;
  letter-spacing: -0.256px;
  color: #6d7d89;
  background: #fff;
  border: none;
  border-radius: 22px;
  filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.2));
  cursor: pointer;
}

.action-button.blue {
  color: #fff;
  background: linear-gradient(264.92deg, #1c23c3 10.3%, #39c0ee 110.14%);
  filter: none;
}

@media (max-width: 767px) {
  .modal-title {
    font-size: 20px;
    line-height: 1;
  }

  .modal-icon {
    margin-bottom: 30px;
  }
}

@media (max-width: 425px) {
  .modal-actions {
    flex-direction: column;
  }

  .action-button {
    margin: 15px 0;
    width: 100%;
  }
}
</style>
