<template>
  <div>
    <div class="modal-backgdround"></div>
    <div class="modal-fade">
      <div class="modal">
        <button @click="modalClose()" class="modal-close">
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.00342 2L20.6685 20.6651"
              stroke="black"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M20.665 2L1.99993 20.6651"
              stroke="black"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </button>
        <div class="modal-content">
          <p v-if="stage === 1 && !isMobile" class="modal-title">
            Drop Files Here to Get Started
          </p>
          <p v-if="stage === 2" class="modal-title">
            Add Images
          </p>
          <ImageAdd v-if="stage === 1" @addFiles="addFiles" />
          <template v-else-if="stage === 2">
            <p class="modal-hint">Tap on title to rename.</p>
            <AddedImages
              :imagesList="filesData.images"
              @addFiles="addFiles"
              @imageRemove="imageRemove"
              @saveFiles="saveFiles"
            />
          </template>
          <template v-else-if="stage === 3">
            <p class="modal-title">Select Album</p>
            <TabsWrapper>
              <TabItem title="Public">
                <div class="row">
                  <div class="col">
                    <AddAlbumButton
                      id="icon-3"
                      modalItem
                      @click="addPublicAlbum"
                    />
                  </div>
                  <div v-if="publicPlaceholder.exist" class="col">
                    <AlbumItem
                      :album="publicPlaceholder"
                      modalItem
                      isPlaceholder
                      @change="createPublicAlbum"
                      @deleteAlbumPlaceholder="deletePublicPlaceholder"
                    />
                  </div>
                  <template v-for="album in publicAlbums">
                    <div
                      v-if="album.can_insert_image"
                      :key="album.id"
                      class="col"
                    >
                      <AlbumItem
                        :album="album"
                        modalItem
                        @click="chooseAlbum"
                      />
                    </div>
                  </template>
                </div>
              </TabItem>
              <TabItem title="Private">
                <div class="row">
                  <div class="col">
                    <AddAlbumButton modalItem @click="addPrivateAlbum" />
                  </div>
                  <div v-if="privatePlaceholder.exist" class="col">
                    <AlbumItem
                      :album="privatePlaceholder"
                      modalItem
                      isPlaceholder
                      @change="createPrivateAlbum"
                      @deleteAlbumPlaceholder="deletePrivatePlaceholder"
                    />
                  </div>
                  <template v-for="album in privateAlbums">
                    <div
                      v-if="album.can_insert_image"
                      :key="album.id"
                      class="col"
                    >
                      <AlbumItem
                        :album="album"
                        modalItem
                        @click="chooseAlbum"
                      />
                    </div>
                  </template>
                </div>
              </TabItem>
              <TabItem title="NFTs">
                <div class="row">
                  <div class="col">
                    <AddAlbumButton
                      id="icon-5"
                      high
                      @click="createCollection"
                      modalItem
                    />
                  </div>
                  <div
                    v-for="collection in nftCollections"
                    :key="collection.id"
                    class="col"
                  >
                    <NftItem
                      :collection="collection"
                      @click="chooseCollection(collection.id)"
                    />
                  </div>
                </div>
              </TabItem>
            </TabsWrapper>
          </template>
          <template v-else>
            <template v-if="!guidelines">
              <p class="modal-title">Permissions & Ownership</p>
              <Permissions
                @confirm="permissionsAccept"
                @openGuidelines="openGuidelines"
              />
            </template>
            <template v-else>
              <p class="modal-title">Guidelines</p>
              <Guidelines @backToPermissions="backToPermissions" />
            </template>
          </template>
        </div>
        <PreloaderComponent v-if="loading" />
      </div>
      <div ref="videoEl" class="hidden-video"></div>
    </div>
  </div>
</template>

<script>
import {
  saveImage,
  sendImageData,
  sendNftData,
} from "../../services/imageData";
import { mapGetters } from "vuex";

import ImageAdd from "./parts/imageAdd.vue";
import AddedImages from "./parts/addedImages.vue";
import TabsWrapper from "../tabs/TabsWrapper.vue";
import TabItem from "../tabs/TabItem.vue";
import AddAlbumButton from "../ArtistsProfile/AddAlbumButton.vue";
import AlbumItem from "../ArtistsProfile/AlbumItem.vue";
import NftItem from "../ArtistsProfile/NftItem.vue";
import Permissions from "./parts/permissions.vue";
import Guidelines from "./parts/guidelines.vue";
import PreloaderComponent from "../preloader.vue";

import albums from "../mixins/albums";
import { mobileDetect } from "../../functions/mobileDetect";

export default {
  mixins: [albums],

  props: {
    loadData: {
      type: Boolean,
      default: false,
    },

    nftOnly: {
      type: Boolean,
      default: false,
    },

    collectionData: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    ImageAdd,
    AddedImages,
    TabsWrapper,
    TabItem,
    AddAlbumButton,
    AlbumItem,
    NftItem,
    Permissions,
    Guidelines,
    PreloaderComponent,
  },

  data() {
    return {
      stage: 1,
      isNft: false,
      filesData: {
        images: [],
        album: {},
      },
      loading: false,
      collectionCreate: false,
      publicPlaceholder: {
        exist: false,
        title: "",
        cover: null,
      },
      privatePlaceholder: {
        exist: false,
        title: "",
        cover: null,
      },
      guidelines: false,
      isMobile: mobileDetect(),
    };
  },
  computed: {
    ...mapGetters(["albums"]),
    ...mapGetters("auth", ["currentUser"]),
  },
  methods: {
    modalClose(reload = false) {
      this.stage = 1;
      this.filesData = [];

      document.body.removeAttribute("style");

      this.$emit("addModalClose", reload);
    },

    addPublicAlbum() {
      this.publicPlaceholder.exist = false;

      this.$nextTick(() => (this.publicPlaceholder.exist = true));
    },

    deletePublicPlaceholder() {
      this.publicPlaceholder.exist = false;
    },

    createPublicAlbum(title) {
      if (title.length) {
        this.loading = true;

        this.deletePublicPlaceholder();
        this.onAddAlbumClick(title, true);
      }
    },

    addPrivateAlbum() {
      this.privatePlaceholder.exist = false;

      this.$nextTick(() => (this.privatePlaceholder.exist = true));
    },

    deletePrivatePlaceholder() {
      this.privatePlaceholder.exist = false;
    },

    createPrivateAlbum(title) {
      if (title.length) {
        this.onAddAlbumClick(title);
        this.deletePrivatePlaceholder();
      }
    },

    async saveFiles(imagesData) {
      this.filesData.images = imagesData;

      if (this.nftOnly) {
        this.loading = true;

        this.filesData.album = {
          id: this.collectionData.id,
        };

        await this.setImagesData();
        await sendNftData(this.filesData);

        this.loading = false;

        this.modalClose(true);

        return;
      }

      this.stage = 3;
    },

    imageRemove(index) {
      this.filesData.images.splice(index, 1);

      if (!this.filesData.images.length) this.stage = 1;
    },

    async addFiles(files) {
      for (const file of Array.from(files)) {
        await this.getImageData(file);
        this.filesData.images.push(file);
      }

      this.stage = 2;
    },

    chooseAlbum(album) {
      this.filesData.album = {
        id: album.id,
        published: album.published,
        title: album.title,
      };

      this.stage = 4;
    },

    async setImagesData() {
      for (let index = 0; index < this.filesData.images.length; index++) {
        const item = this.filesData.images[index];

        if (item.status === "validated") {
          item.status = "sent-to-amazon";
          await saveImage(item);
        }
      }
    },

    getImageData(file) {
      return new Promise((resolve) => {
        const fr = new FileReader();

        file.allow_publish = true;
        file.tags = [];

        if (file.size / 1024 / 1024 > 50) file.error = "File has high size";

        if (file.type.match("image")) {
          this.readImage(fr, resolve, file);

          return;
        }

        window.URL = window.URL || window.webkitURL;

        const video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function() {
          window.URL.revokeObjectURL(video.src);

          if (Math.floor(video.duration) > 30) {
            file.error = "Video over 30 second limit.";
          }
        };

        video.src = URL.createObjectURL(file);

        this.readVideo(fr, resolve, file, this.$refs.videoEl);
      });
    },

    readImage(fr, resolve, file) {
      fr.onload = function() {
        var img = new Image();

        img.onload = function(e) {
          file.width = img.width;
          file.height = img.height;

          if (
            file.type !== "image/svg+xml" &&
            (img.width < 200 || img.height < 200)
          )
            file.error = "File has low resolution";

          file.status = "validated";
          resolve(file);
        };

        file.src = fr.result;
        img.src = fr.result;
      };

      fr.readAsDataURL(file);
    },

    readVideo(fr, resolve, file, ref) {
      fr.onload = function() {
        const blob = new Blob([fr.result], { type: file.type });
        const url = URL.createObjectURL(blob);

        file.width = 500;
        file.height = 500;

        const video = document.createElement("video");

        video.classList.add("hidden-video");
        video.setAttribute("src", url);
        ref.appendChild(video);

        video.addEventListener("loadedmetadata", function(e) {
          file.status = "validated";
          file.width = video.videoWidth;
          file.height = video.videoHeight;
          file.src = url;
          video.remove();
          resolve(file);
        });
      };
      fr.readAsArrayBuffer(file);
    },

    chooseCollection(id) {
      this.isNft = true;
      this.filesData.album = {
        id,
      };
      this.stage = 4;
    },

    createCollection() {
      this.collectionCreate = true;
      this.stage = 4;
    },

    openGuidelines() {
      this.guidelines = true;
    },

    backToPermissions() {
      this.guidelines = false;
    },

    async permissionsAccept() {
      this.loading = true;

      await this.setImagesData();

      if (this.collectionCreate) {
        this.$store.dispatch("setNftData", this.filesData);
        this.$router.push("/create-collection");
      } else {
        if (this.isNft) {
          await sendNftData(this.filesData);

          if (this.$route.params.collectionId !== this.filesData.album.id)
            this.$router.push({
              name: "NftCollection",
              params: {
                collectionId: this.filesData.album.id,
              },
            });
        } else {
          await sendImageData(this.filesData);

          if (this.filesData.album.published) {
            if (this.$route.params.albumId !== this.filesData.album.id)
              this.$router.push({
                name: "ArtistsAlbumDetails",
                params: {
                  id: this.currentUser.id,
                  albumId: this.filesData.album.id,
                },
                query: {
                  title: this.filesData.album.title,
                },
              });
          } else {
            if (this.$route.params.albumId !== this.filesData.album.id)
              this.$router.push({
                name: "AlbumsPage",
                params: {
                  albumId: this.filesData.album.id,
                },
                query: {
                  user_id: this.currentUser.id,
                  title: this.filesData.album.title,
                },
              });
          }
        }
      }

      this.loading = false;

      this.modalClose();
    },
  },

  mounted() {
    document.body.style.overflow = "hidden";

    if (this.loadData) this.getData();
  },

  watch: {
    albums() {
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.row {
  margin: 0 -15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.col {
  margin-bottom: 25px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  width: 50%;
  box-sizing: border-box;
}

@media (max-width: 788px) {
  .row {
    margin: 0 -10px;
  }

  .col {
    padding: 0 10px;
  }
}

.modal-hint {
  margin: -31px 0 40px;
  color: #3a4146;
  font-family: SF Pro Display;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.26px;
  text-align: center;
}

@media (max-width: 768px) {
  .modal-hint {
    margin: -29px 0 24px;
  }
}

.modal-fade {
  padding: 25px 20px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
    z-index: 1001;

  overflow-x: hidden;
  overflow-y: auto;
}

.modal-backgdround {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  margin: auto;
  padding: 15px 15px 34px;
  max-width: 774px;
  height: fit-content;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .modal {
    padding: 0;
  }
}

.modal-close {
  position: absolute;
  top: 36px;
  right: 36px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}

@media (max-width: 991px) {
  .modal-close {
    top: 22px;
    right: 22px;
  }
}

@media (max-width: 767px) {
  .modal-close {
    top: 18px;
    right: 18px;
  }
}

.modal-title {
  margin-bottom: 38px;
  color: #1e2541;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

@media (max-width: 767px) {
  .modal-title {
    font-size: 24px;
    line-height: 1;
  }
}

.modal-content {
  padding: 69px 55px 0;
  width: 100%;
}

.hidden-video {
  display: none;
}

@media (max-width: 565px) {
  .modal-content {
    padding: 69px 17px 0;
  }
}
</style>
