<template>
  <div>
    <p class="text-bold">
      You CANNOT sell any content that is not yours.
    </p>
    <p>
      Please don’t try. We will find it. It is breaking the law. You will get
      kicked out of hte platform and might get sued by the creator.
    </p>
    <br />
    <p class="text-bold">
      You CAN publish Fanart or Creative Commons content if you post it for
      FREE.
    </p>
    <p>
      Or make sure you have written permission from the creator. We may ask for
      this proof.
    </p>
    <br />
    <p class="text-bold">
      Please credit the creator is your are not the author.
    </p>
    <p>
      <i>Content Title</i> by <i>Name of Creator</i>. For example, “
      <i>
        Cute Cat
      </i>
      by <i>John Doe</i>”
    </p>
    <br />
    <p>
      Be sure you understand what Creative Commons license applies to content
      and follow these rules.
    </p>
    <v-button @click="goBack">Back</v-button>
  </div>
</template>

<script>
import vButton from "../../ui/v-button.vue";

export default {
  components: {
    vButton,
  },

  methods: {
    goBack() {
      this.$emit("backToPermissions");
    },
  },
};
</script>

<style scoped>
p {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.256px;
}

.text-bold {
  font-weight: 700;
}

::v-deep .button {
  display: block;
  margin: 40px auto 0;
}
</style>
