/* eslint-disable indent */

import { createTwitterTags } from './twitter-tags';
import { createArtTags } from './art-tags';
import { createGifTags } from './gif-tags';
export default function createTagsData(data) {
  const twitterTags = createTwitterTags(data);
  let imageTags = [];
  switch (data.type) {
    case 'Art':
      imageTags = createArtTags(data);
      break;
    case 'Gif':
      imageTags = createGifTags(data);
      break;
    case 'Sticker':
      if(data.image.animated){
        imageTags = createGifTags(data);
      } else {
        imageTags = createArtTags(data);
      }
      break;

    default:
      imageTags = createArtTags(data);
      break;
  }


  return [...imageTags, ...twitterTags];

};



