import { mapGetters } from "vuex";

import services from "../../../services";

const albums = {
  data() {
    return {
      user: {},
    };
  },

  computed: {
    ...mapGetters(["albums", "pickAlbums", "nftCollections", "nftCollected"]),
    ...mapGetters("auth", ["currentUser", "isAuthenticated"]),

    userId() {
      if (this.$route.params.id) return this.$route.params.id;
      if (this.isAuthenticated) return this.currentUser.id;
    },

    isProfilePage() {
      return this.$route.name === "Profile";
    },

    albumsCollection() {
      return this.albums && this.albums.collection
        ? this.albums.collection
        : [];
    },

    publicAlbums() {
      return this.albumsCollection.filter((item) => item.published);
    },

    privateAlbums() {
      return this.albumsCollection.filter((item) => !item.published);
    },

    pickAlbumsCollection() {
      return this.pickAlbums && this.pickAlbums.collection
        ? this.pickAlbums.collection
        : [];
    },

    pickPublicAlbums() {
      return this.pickAlbumsCollection.filter((item) => item.published);
    },

    pickPrivateAlbums() {
      return this.pickAlbumsCollection.filter((item) => !item.published);
    },
  },

  methods: {
    async getUser() {
      this.user = await services.user.fetchUser(this.userId);
    },

    async fetchNftCollections() {
      const artistId =
        this.$route.name === "ArtistPage" ? this.$route.params.id : null;

      const payload = await services.user.fetchNftCollections(artistId);

      this.$store.dispatch("setNftCollections", payload);

      if (!artistId) {
        const collectedData = await services.user.fetchNftCollected();

        this.$store.dispatch("setNftCollected", collectedData);
      }
    },

    async fetchUserAlbums(remoteId = false, pickAlbums = false) {
      const response =
        this.$route.params.id && !remoteId
          ? await services.user.fetchArtistAlbums(this.userId)
          : await services.user.fetchUserAlbums();

      if (response === "error") {
        return;
      }

      if (response.total_pages > 1) {
        for (let i = 2; i <= response.total_pages; i++) {
          const pageResponse = await services.user.fetchUserAlbums(i);
          response.collection = [
            ...response.collection,
            ...pageResponse.collection,
          ];
        }
      }

      const payload = response || [];

      if (pickAlbums) {
        this.$store.dispatch("setPickAlbums", payload);

        return;
      }

      this.$store.dispatch("setAlbums", payload);
    },

    async getData(remoteId = false) {
      await this.fetchUserAlbums(remoteId);
      this.fetchNftCollections();
    },

    async onAlbumItemChange(published, index) {
      await this.fetchUserAlbums();

      if (!published) {
        this.albums.collection[index].published = true;
      }
    },

    async onAddAlbumClick(title, published = false) {
      if (this.currentUser.status === "not_confirmed") {
        if (this.confirmModal) this.confirmModal = true;
      } else {
        const payload = {
          title,
          published,
        };

        await services.albums.createAlbum(payload);
        await this.fetchUserAlbums(this.userId);
      }
    },
  },
};

export default albums;
