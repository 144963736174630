const apiUrl = process.env.BASE_URL;
export const createTwitterTags = (data) => {

  const getTwitterImage = (data) => {
    const versions = Object.values(data.image.versions);
    let key;
    let val;

    if (data.provider === 'giphy') {
      return createGiphyTwitterMetaTags(data);

    } else {
      if (data.image.is_video || data.image.animated) {
        key = `preview_jpeg_size`;
        val = `preview_jpeg`;
      } else {
        key = `size`;
        val = `url`;
      }

      const sorted = versions.filter(el => {
        return !!el[key];
      }).sort((prev, next) => {
        return prev[key] > next[key];
      });
      const index = sorted.findIndex((el, ind) => {
        return el[key] < 5000000;
      });

      return sorted[index][val];
    }
  };

  return [
    {
      tagName: 'meta',
      tagAttr: 'name',
      tagAttrValue: 'description',
      content: getTwitterDescription(data)
    },
    
    {
      tagName: 'meta',
      tagAttr: 'name',
      tagAttrValue: 'twitter:site',
      content: '@Pixchange_app'
    },
    {
      tagName: 'meta',//twTitle
      tagAttr: 'name',
      tagAttrValue: 'twitter:title',
      content: data.title ? data.title : ''
    },
    {
      tagName: 'meta',
      tagAttr: 'name',
      tagAttrValue: 'twitter:description',
      content: getTwitterDescription(data)
    },
    {
      tagName: 'meta',//twTextTitle
      tagAttr: 'name',
      tagAttrValue: 'twitter:domain',
      content: `${apiUrl}`
    },
    {
      tagName: 'meta',//twTextTitle
      tagAttr: 'name',
      tagAttrValue: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      tagName: 'meta',//twImage
      tagAttr: 'name',
      tagAttrValue: 'twitter:image',
      content: getTwitterImage(data)// 5
    }
  ];


};


const createGiphyTwitterMetaTags = (data) =>{
  return data.image.giphy_versions['480w_still'].url;
};


const getTwitterDescription = (data) =>{
  return data.image.animated ?  'Click Link to Play' : '';
};