<template>
  <div v-if="vShowRender" v-show="isActive">
    <slot />
  </div>
  <div v-else-if="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },

    query: {
      type: String,
      default: "",
    },

    vShowRender: {
      type: Boolean,
      default: true,
    },
  },

  inject: ["providedTitle"],

  data() {
    return {
      test: null,
    };
  },

  computed: {
    selectedTitle() {
      return this.providedTitle();
    },

    isActive() {
      return this.selectedTitle === this.title;
    },
  },
};
</script>
