const env = process.env;
import config from '../../../config/apps';

const noindex = {
  name: 'robots',
  content: "noindex"
};
const metaTags = [
  {
    name: 'fb:app_id',
    content: config[process.env.NODE_ENV]
  },
  {
    name: 'description',
    content: 'Pixchange: Join our Gif, Sticker, and AR-3D Community'
  },
  {
    property: 'og:description',
    content: 'Pixchange: Join our Gif, Sticker, and AR-3D Community'
  },
  {
    property: 'og:title',
    content: 'Pixchange'
  },
  {
    property: 'og:image:width',
    content: '300'
  },
  {
    property: 'og:image:height',
    content: '200'
  },
  {
    property: 'og:image',
    content: 'https://pixchangeapp.com/public/app_icon.png'
  },
  {
    property: 'og:image:type',
    content: 'image/jpeg'
  }
];

if(process.env.NODE_ENV !== 'production'){
  metaTags.push(noindex);
}
export default metaTags;