import axios from "axios";

const postLink =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBnWO8SQxVaFRwbieAzL4JIZyhTN8MI4Bo";

export async function deepLinkTransformMain(link) {
  const { data } = await axios.post(
    postLink,
    {
      dynamicLinkInfo: {
        domainUriPrefix: "https://pixchange.page.link",
        link,
        androidInfo: {
          androidPackageName: "com.pixchange.pixchange",
        },
        iosInfo: {
          iosBundleId: "com.pixchange.pixchange",
        },
      },
    },
    {
      "Content-Type": "application/json",
    }
  );

  return data;
}

export async function deepLinkTransformStudio(link) {
  const { data } = await axios.post(
    postLink,
    {
      dynamicLinkInfo: {
        domainUriPrefix: "https://pixchangestudio.page.link",
        link,
        androidInfo: {
          androidPackageName: "com.pixchange.camera",
        },
        iosInfo: {
          iosBundleId: "com.pixchange.camera",
        },
      },
    },
    {
      "Content-Type": "application/json",
    }
  );

  return data;
}
