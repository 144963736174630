<template>
  <button @click="buttonClick" :class="{ blue }" class="upload-button">
    <svg
      class="upload-button-icon"
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H15C15.5304 21 16.0391 20.7893 16.4142 20.4142C16.7893 20.0391 17 19.5304 17 19V11"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 5L9 1L5 5"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 1V14"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    Upload
  </button>
</template>

<script>
export default {
  props: {
    blue: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    buttonClick() {
      this.$emit("click");
    },
  },

  mounted() {
    if (sessionStorage.getItem("uploadModal")) {
      this.buttonClick();
      sessionStorage.removeItem("uploadModal");
    }
  },
};
</script>

<style scoped>
.upload-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 136px;
  height: 50px;
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  background: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.upload-button:not(.blue) {
  box-shadow: 0px 4px 10px rgba(49, 146, 226, 0.3);
}

.upload-button.blue {
  margin-left: auto;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  background: linear-gradient(90.29deg, #34a6e7 -2.63%, #2653d0 103.1%);
  border-radius: 50px;
}

@media (max-width: 1199px) {
  .upload-button.blue {
    color: #000;
    background: #fff;
    filter: drop-shadow(0px 4px 10px rgba(44, 145, 179, 0.3));
  }

  .blue .upload-button-icon path {
    stroke: #000 !important;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .upload-button:not(.blue) {
    right: 19px;
  }

  .upload-button.blue {
    margin-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .upload-button.blue {
    margin-bottom: 0;
  }
}

.upload-button-icon {
  margin-right: 7px;
  position: relative;
  right: 3px;
}

@media (max-width: 767px) {
  .upload-button {
    font-size: 12px;
    height: 40px;
    min-width: 0;
    width: 100px;
  }

  .upload-button-icon {
    width: 17px;
  }
}

.blue .upload-button-icon path {
  stroke: #fff;
}
</style>
