const apiUrl = process.env.BASE_URL;
const token = localStorage.getItem("token");

const AlbumsService = {
  createAlbum(payload) {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ album: payload }),
    };

    return fetch(`${apiUrl}/api/albums`, options)
      .then((response) => response.json())
      .catch((err) => {
        throw err;
      });
  },
  deleteAlbum(id) {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    };
    return fetch(`${apiUrl}/api/albums/${id}`, options).catch((err) => {
      throw err;
    });
  },
  publishAlbum(id) {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
    };

    return fetch(`${apiUrl}/api/albums/${id}/publish`, options)
      .then((response) => response.json())
      .catch((err) => {
        throw err;
      });
  },
  unpublishAlbum(id) {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "PATCH",
    };

    return fetch(`${apiUrl}/api/albums/${id}/unpublish`, options)
      .then((response) => response.json())
      .catch((err) => {
        throw err;
      });
  },
  updateAlbum(payload) {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify({ album: payload }),
    };

    return fetch(`${apiUrl}/api/albums/${payload.id}`, options)
      .then((response) => response.json())
      .catch((err) => {
        throw err;
      });
  },
};

export default AlbumsService;
